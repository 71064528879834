import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { AgreementTableFilter } from '@core/models/filter-types.model';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
export class AgreementCreditsListComponent {
    constructor(activatedRoute, agreementCreditsService, tableTemplateControls, translateService, lookupService, helperService, _location, router, store) {
        this.activatedRoute = activatedRoute;
        this.agreementCreditsService = agreementCreditsService;
        this.tableTemplateControls = tableTemplateControls;
        this.translateService = translateService;
        this.lookupService = lookupService;
        this.helperService = helperService;
        this._location = _location;
        this.router = router;
        this.store = store;
        this.showTableDetails = true;
        this._destroy$ = new Subject();
        this._stopPrevious$ = new Subject();
        this.filterFields = AgreementTableFilter;
        this.isFirstTimePaginationEvent = true;
        this.pagination = {
            page: 0,
            size: 5,
        };
        this.firstItemIndex = 0;
        this.filterState = {};
    }
    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.registrationAccountId = params.id;
            }
        });
        if (!this.showTableDetails) {
            this.loadData(this.pagination, this.filterState);
        }
        this.tableConfiguartion();
        this.executeActions();
    }
    executeActions() {
        this.tableTemplateControls._action.pipe(takeUntil(this._destroy$)).subscribe(metadata => {
            this.agreementCreditsService.refreshData$
                .pipe(first()).subscribe(() => this.refreshData(null));
            this.agreementCreditsService.triggerActionController(metadata);
        });
    }
    tableConfiguartion() {
        this.lookupService.getTableConfigurations('AGREEMENT_DETAIL').subscribe(data => {
            this.tableConfig = data;
            const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
            const _columns = [
                ...this.tableConfig.columns,
                {
                    header: 'view',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.viewColTemplate,
                }, {
                    header: 'actions',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.actionTemplate,
                },
            ];
            this.tableConfig = Object.assign({}, config, { columns: _columns });
        });
    }
    close() {
        this.router.navigate(this.helperService.getTranslatedPath('/registration-pending'));
    }
    loadData(pagination, filterState = {}) {
        if (this.registrationAccountId) {
            filterState.registrationAccountId = this.registrationAccountId;
        }
        this._stopPrevious$.next();
        this.agreementCreditsService.getAgreementList(pagination, filterState)
            .pipe(takeUntil(this._destroy$), takeUntil(this._stopPrevious$)).subscribe(data => {
            console.log(data);
            data.content.forEach(rowrecord => {
                const index = rowrecord.entityActionList.findIndex(o => o.workflowAction.toString() === 'VIEW_SIGNED_AGREEMENT');
                if (index > -1 && rowrecord.opaSignedOn) {
                    rowrecord.entityActionList.splice(index, 1);
                }
            });
            this.dataList = data;
        });
    }
    viewAgreement($event, data) {
        this.agreementCreditsService.saveHistoryAndRedirectToAgreementScreen(null, data.id);
    }
    createNewAgreement() {
        this.agreementCreditsService.getAgreementOpaList(this.registrationAccountId).pipe(takeUntil(this._destroy$)).subscribe(response => {
            this.agreementCreditsService.saveHistoryAndRedirectToAgreementScreen(response);
        });
    }
    onFilterChanged(filterData) {
        this.firstItemIndex = 0;
        this.filterState = filterData;
        this.loadData(this.pagination, this.filterState);
    }
    onPaginationChanged(pagination) {
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        // prevent first pagination call
        if (this.isFirstTimePaginationEvent) {
            this.isFirstTimePaginationEvent = false;
            return; // ignore first call
        }
        this.loadData(this.pagination, this.filterState);
    }
    refreshData(event) {
        this.loadData(this.pagination, this.filterState);
    }
    viewAccount(event, data) { }
    onCustomButtonAction(method) {
        if (typeof this[method] === 'function') {
            this[method]();
        }
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
