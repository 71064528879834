import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/store/store.service";
export const PRIVATE_USER = '/account-service/private/user';
export const NEW_PRIVATE_USER = '/account-service/private/user/new';
export const DICT_ROLES_URI = '/roles';
export const DICT_VB_ROLES_URI = '/roles/vb';
export const DICT_PROGRAMS_URI = '/account-service/lookup/standards';
export const USER_ASSOCIATION_ACTION = '/account-service/private/user/associationAction';
// OBPS
export const USER_CREATE = '/newcreate';
export const USER_UPDATE = '/newupdate';
export const SELF_UPDATE = '/selfUpdate';
// CFR
export const CFR_USER_CREATE = '/cfrCreate';
export const CFR_USER_UPDATE = '/cfrUpdate';
export const CFR_USER_SELF_UPDATE = '/cfrSelfUpdate';
// OFFSET
export const OFFSET_USER_CREATE = '/offsetCreate';
export const OFFSET_USER_UPDATE = '/offsetUpdate';
export const OFFSET_APPROVE_USER = '/doOffsetApprove';
export const OFFSET_REJECT_USER = '/doOffsetReject';
export const OFFSET_APPROVE_ASSOCIATION = '/associationApprove';
export const OFFSET_REJECT_ASSOCIATION = '/associationReject';
export class UserService {
    constructor(http, store) {
        this.http = http;
        this.store = store;
        this._roleDict$ = new BehaviorSubject(null);
        this.roleDict$ = this._roleDict$.asObservable();
        this._programDict$ = new BehaviorSubject(null);
        this.programDict$ = this._programDict$.asObservable();
    }
    get(userId) {
        return this.http.get(`${environment.apiUrl}${NEW_PRIVATE_USER}/${userId}`);
    }
    saveUser(userData) {
        let uri = isNaN(userData.id) ? USER_CREATE : USER_UPDATE;
        if (this.store.user.program === 'CFR') {
            uri = isNaN(userData.id) ? CFR_USER_CREATE : CFR_USER_UPDATE;
            if (this.isSelfUpdate(userData, true)) {
                uri = CFR_USER_SELF_UPDATE;
            }
        }
        else {
            if (this.store.user.program === 'OFFSET') {
                uri = isNaN(userData.id) ? OFFSET_USER_CREATE : OFFSET_USER_UPDATE;
            }
            if (this.isSelfUpdate(userData, true)) {
                uri = SELF_UPDATE;
            }
        }
        return this.http.post(`${environment.apiUrl}${PRIVATE_USER}${uri}`, userData);
    }
    isSelfUpdate(userData, bypass = false) {
        if (!this.checkUserdetailPresent(bypass)) {
            return userData.id === this.store.user.id;
        }
    }
    checkUserdetailPresent(bypass) {
        const userDetailsPresent = this.store.user.businessTelephone; //&& this.store.user.title;
        if (this.store.user.isCFR() || this.store.user.isOffset() || this.store.user.isOBPS()) {
            if (this.store.user.hasRole('ACCOUNT_HOLDER_ENHANCED_USER') && this.store.user.associationsList[0].accountDto) {
                if (this.store.user.associationsList[0].accountDto.status === 'PENDING_KYC') {
                    return true;
                }
                else if (this.store.user.status === 'ACTIVE') {
                    return true;
                }
            }
            return bypass ? false : userDetailsPresent && this.store.user.civicAddress;
        }
        return userDetailsPresent;
    }
    doAction(uri, userData) {
        return this.http.post(`${environment.apiUrl}${PRIVATE_USER}` + uri, userData);
        // return this.http.post<IUserData>('http://localhost:8084/api/account-service/private/user/' + uri , userData);
    }
    getRoleDict() {
        if (this._roleDict$.getValue()) {
            return this.roleDict$;
        }
        else {
            // const uri = this.store.user.checkIfVerificationBody() ? DICT_VB_ROLES_URI : DICT_ROLES_URI;
            const uri = DICT_ROLES_URI;
            return this.http.get(`${environment.apiUrl}${PRIVATE_USER}${uri}`)
                .pipe(tap(data => this._roleDict$.next(data)));
        }
    }
    getRoleByAccount(accountId) {
        return this.http.get(`${environment.apiUrl}${PRIVATE_USER}${DICT_ROLES_URI}?accountId=${accountId}`)
            .pipe(tap(data => data));
    }
    getProgramDict() {
        if (this._programDict$.getValue()) {
            return this.programDict$;
        }
        else {
            return this.http.get(`${environment.apiUrl}${DICT_PROGRAMS_URI}`)
                .pipe(tap(data => this._programDict$.next(data)), shareReplay(1));
        }
    }
    setAssociationAction(data) {
        return this.http.post(`${environment.apiUrl}${USER_ASSOCIATION_ACTION}`, data);
    }
    setAssociationApprovalAction(actionType, associationId) {
        const actionUrl = actionType.toUpperCase() === 'APPROVE' ? OFFSET_APPROVE_ASSOCIATION : OFFSET_REJECT_ASSOCIATION;
        return this.http.post(`${environment.apiUrl}${PRIVATE_USER}${actionUrl}`, { id: associationId });
    }
    clearCache() {
        this._roleDict$.next(null);
        this._programDict$.next(null);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StoreService)); }, token: UserService, providedIn: "root" });
