import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { AgreementTableFilter } from '@core/models/filter-types.model';
import { FilterModel } from '@core/models/filter.model';
import { LookupService } from '@core/services/lookup.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { StoreService } from '@core/store/store.service';
import { CreateNewAgreementComponent } from '@module/submission-management/components/create-new-agreement/create-new-agreement.component';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';

@Component({
  selector: 'app-agreement-credits-list',
  templateUrl: './agreement-credits-list.component.html',
  styleUrls: ['./agreement-credits-list.component.scss']
})
export class AgreementCreditsListComponent implements OnInit {

  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;

  @Input() showTableDetails: boolean = true;
  @Input() registrationAccountId: number;

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();

  filterFields = AgreementTableFilter;
  private isFirstTimePaginationEvent =true

  private pagination = {
    page: 0,
    size: 5,
  } as IApp.IPagination;
  firstItemIndex = 0;
  filterState: FilterModel = {};

  tableConfig: ITableViewConfig;
  dataList: any[];

  constructor(
    public activatedRoute: ActivatedRoute,
    private agreementCreditsService: AgreementCreditsService,
    private tableTemplateControls: TableTemplateControls,
    private translateService: TranslateService,
    private lookupService: LookupService,
    public helperService: HelperService,
    private _location: Location,
    private router: Router,
    private store: StoreService) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.registrationAccountId = params.id;
      }
    });
    if (!this.showTableDetails) {
      this.loadData(this.pagination, this.filterState);
    }

    this.tableConfiguartion();

    this.executeActions();
  }

  executeActions() {
    this.tableTemplateControls._action.pipe(
      takeUntil(this._destroy$),
    ).subscribe(metadata => {
      this.agreementCreditsService.refreshData$
        .pipe(first()).subscribe(() => this.refreshData(null));
      this.agreementCreditsService.triggerActionController(metadata);
    });
  }

  tableConfiguartion() {

    this.lookupService.getTableConfigurations('AGREEMENT_DETAIL').subscribe(
      data => {

        this.tableConfig = data;

        const { columns, ...config } = this.tableConfig;

        const _columns: any = [
          ...this.tableConfig.columns,
          {
            header: 'view',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          }, {
            header: 'actions',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.actionTemplate,
          },
        ];

        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };

      });
  }

  close() {
    this.router.navigate(this.helperService.getTranslatedPath('/registration-pending'));
  }

  private loadData(pagination: IApp.IPagination, filterState: FilterModel = {}) {
    if (this.registrationAccountId) {
      filterState.registrationAccountId = this.registrationAccountId;
    }
    this._stopPrevious$.next();
    this.agreementCreditsService.getAgreementList(pagination, filterState)
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(
        data => {
          console.log(data);
          data.content.forEach(rowrecord => {
            const index = rowrecord.entityActionList.findIndex(
              o => o.workflowAction.toString() === 'VIEW_SIGNED_AGREEMENT') ;
            if (index > -1 && rowrecord.opaSignedOn) {
              rowrecord.entityActionList.splice(index, 1);
            }
          });
          this.dataList = data;
        }
      );
  }

  viewAgreement($event, data) {
    this.agreementCreditsService.saveHistoryAndRedirectToAgreementScreen(null, data.id);
  }

  createNewAgreement() {
    this.agreementCreditsService.getAgreementOpaList(this.registrationAccountId).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      response => {
        this.agreementCreditsService.saveHistoryAndRedirectToAgreementScreen(response);
      },
    );
  }

  onFilterChanged(filterData: any,) {
    this.firstItemIndex = 0;
    this.filterState = filterData;
    this.loadData(this.pagination, this.filterState);
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    
    // prevent first pagination call
    if(this.isFirstTimePaginationEvent){
      this.isFirstTimePaginationEvent =false;
      return // ignore first call
    }
    this.loadData(this.pagination, this.filterState);
  }

  refreshData(event) {
    this.loadData(this.pagination, this.filterState);
  }

  viewAccount(event, data) { }


  onCustomButtonAction(method) {
    if (typeof this[method] === 'function') {
      this[method]();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
