import { ITableFilterActionModel, ActionOptionType } from '@shared/models/table-filter.models';
export const commonActionsLabelTranslationPrefix = 'COMMON.actionsLabel.';
export const commonFilterTranslationPrefix = 'COMMON.filters.';
export const reportsFilterTranslationPrefix = 'REPORTS_MODULE.filters.';
export const insuffCreditsReportsFilterTranslationPrefix = 'REPORTS_MODULE.insufficientCreditsReports.';
export const creditAgreementTucReportTranslationPrefix = 'REPORTS_MODULE.creditAgreementTucReport.';


export const verificationTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'organizationNameText',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'verificationBodyNameText',
      itemsType: 'vbNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'vbNameList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'assignment',
      itemsType: 'vbSubmissionType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'submissionTypeList'
    }

  ],
  actionModel: [
    {
      placeholder:  commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_VERIFICATION_BODY_ASSIGNMENT'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_VERIFICATION_BODY_ASSIGNMENT'
        }
      ]
    }
  ]
};

export const agreementTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'issueYearText',
      itemsType: 'agreementIssueYear',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'rcaName',
      itemsType: 'rcaName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'rcaNameList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'opaName',
      itemsType: 'opaName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'opaFacilityList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'agreementActivity',
      itemsType: 'agreementActivity',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'activityList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'agreementStatus',
      itemsType: 'agreementStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'currentStatusList'
    }

  ],
  actionModel: [
    {
      placeholder:  commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_CREDIT_AGREEMENT'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_CREDIT_AGREEMENT'
        }
      ]
    }
  ]
};

export const CompTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'facilityNameText',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'remittanceTypesText',
      itemsType: 'remittanceTypes',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'remittanceTypesList'
    },



  ],
  actionModel: [
    {
      placeholder:  commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_COMPENSATION'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_COMPENSATION'
        }
      ]
    }
  ]
};

export const CrossCompTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [

    {
      placeholder: commonFilterTranslationPrefix + 'facilityNameText',
      itemsType: 'crossFacilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'crossFacilityNameList'
    },
    {
      placeholder: commonFilterTranslationPrefix + 'remittanceTypesText',
      itemsType: 'remittanceTypes',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'remittanceTypesList'
    },



  ],
  actionModel: [
    {
      placeholder:  commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_COMPENSATION'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_COMPENSATION'
        }
      ]
    }
  ]
};

export const consolidatedTransactionsFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_CONSOLIDATED_TRANSACTION'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_CONSOLIDATED_TRANSACTION'
        }
      ]
    }
  ]
};

export const ecccPaymentTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_EEC_PAYMENT'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_EEC_PAYMENT'
        }
      ]
    }
  ],
  options: {
    dateRange: {
      placeholder: 'REPORTS_MODULE.eecPaymentList.paymentDate',
    },
  },
};

export const creditTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_CREDITS'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_CREDITS'
        }
      ]
    }
  ]
};

export const creditHistoryTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_CFR_CREDIT_HISTORY'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_CFR_CREDIT_HISTORY'
        },
      ],
    },
  ],
  options: {
    datePicker: {
      dateField: 'endDate',
      format: 'YYYY-MM-DDT23:59:59',
    },
    dateRange: {
      placeholder: 'REPORTS_MODULE.creditsHistoryList.activityDate'
    }
  },
};

export const insufficientCreditsReportsFilters: ITableFilterActionModel =
{
  searchBox: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_INSUFFICIENT_CREDITS_TO_CANCEL'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_INSUFFICIENT_CREDITS_TO_CANCEL'
        }
      ]
    }
  ]
};


export const creditTransferTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_CREDIT_TRANSFER'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_CREDIT_TRANSFER'
        }
      ]
    }
  ]
};


export const ruRemittanceTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_RECOGNIZED_UNIT_REMITTANCE'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_RECOGNIZED_UNIT_REMITTANCE'
        }
      ]
    }
  ]
};

export const accountTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  // filterListsModel: [
  //   {
  //     placeholder: commonFilterTranslationPrefix + 'accountNameText',
  //     itemsType: 'facilityNames',
  //     multiple: true,
  //     selectableGroup: true,
  //     closeOnSelect: false,
  //     ngModel: 'facilityNameList',
  //   },
  //   {
  //     placeholder: 'ACCOUNTS_MODULE.accountList.status',
  //     itemsType: 'cfrfacilityStatus',
  //     multiple: true,
  //     selectableGroup: true,
  //     closeOnSelect: false,
  //     ngModel: 'status',
  //   },
  // ],
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_ACCOUNT_DETAIL'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_ACCOUNT_DETAIL'
        }
      ]
    }
  ]
};

export const itpTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_INTENT_TO_PAY'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_INTENT_TO_PAY'
        }
      ]
    }
  ]
};


export const refundReportTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_REQUEST_REFUND'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_REQUEST_REFUND'
        }
      ]
    }
  ]
};

export const outCompTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList'
    }

  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_OUTSTANDING_CO'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_OUTSTANDING_CO'
        }
      ]
    }
  ]
};

export const crossOutCompTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: false,
  filterListsModel: [
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_OUTSTANDING_CO'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_OUTSTANDING_CO'
        }
      ]
    }
  ]
};

export const rejectedProjectsTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'projectName',
      itemsType: 'searchOffsetRejectedProjectName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'projectIdList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'protocolType',
      itemsType: 'offsetProtocol',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'offsetProtocolList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'projectJurisdiction',
      itemsType: 'projectJurisdiction',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'jurisdictionList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_REJECTED_PROJECT'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_REJECTED_PROJECT'
        }
      ]
    }
  ]
};

export const registeredProjectsTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [
    {
      placeholder: reportsFilterTranslationPrefix + 'searchProjectIds',
      itemsType: 'searchOffsetProjectIds',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'projectCodeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'projectName',
      itemsType: 'searchOffsetRegisteredProjectName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'projectIdList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'alOrgNames',
      itemsType: 'alOrgNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'proponentList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'offsetProtocol',
      itemsType: 'offsetProtocol',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'offsetProtocolList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'projectJurisdiction',
      itemsType: 'projectJurisdiction',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'jurisdictionList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'REPORT_REGISTERED_PROJECTS'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'REPORT_REGISTERED_PROJECTS'
        }
      ]
    }
  ]
};

export const projectDeadlinesTableFilters: ITableFilterActionModel =
{
  searchBox: true,
  dateRange: true,
  filterListsModel: [
    {
      placeholder: reportsFilterTranslationPrefix + 'accountNameText',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'projectName',
      itemsType: 'searchOffsetRejectedProjectName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'projectIdList',
    },
    {
      placeholder: reportsFilterTranslationPrefix + 'deadLineType',
      itemsType: 'deadLineType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'deadLineTypeList',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + "tools",
      options: [
        {
          id: "exportPdf",
          label:commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_DEADLINE_REPORT'
        },
        {
          id: "exportExcel",
          label:commonActionsLabelTranslationPrefix+'exportExcel',
           type: ActionOptionType.EXPORT,
           uri: 'PROJECT_DEADLINE_REPORT'
        }
      ]
    }
  ]
};

export const creditAgreementTucReportTableFilters: ITableFilterActionModel =
  {
    searchBox: true,
    dateRange: true,
    filterListsModel: [],
    actionModel: [
      {
        placeholder:  commonActionsLabelTranslationPrefix + "tools",
        options: [
          {
            id: "exportPdf",
            label:commonActionsLabelTranslationPrefix + 'exportPdf',
            type: ActionOptionType.EXPORT,
            uri: 'REPORT_TRANSFER_CREDIT_UPON_CREATION_AGREEMENT'
          },
          {
            id: "exportExcel",
            label:commonActionsLabelTranslationPrefix + 'exportExcel',
            type: ActionOptionType.EXPORT,
            uri: 'REPORT_TRANSFER_CREDIT_UPON_CREATION_AGREEMENT'
          }
        ]
      }
    ]
  };

export const overallCreditStatusTableFilters: ITableFilterActionModel =
  {
    searchBox: true,
    dateRange: false,
    filterListsModel: [],
    actionModel: [
      {
        placeholder:  commonActionsLabelTranslationPrefix + "tools",
        options: [
          {
            id: 'exportPdf',
            label: 'COMMON.actionsLabel.exportPdf',
            type: ActionOptionType.EXPORT,
            uri: 'REPORT_CREDIT_STATUS'
          },
          {
            id: 'exportExcel',
            label: 'COMMON.actionsLabel.exportExcel',
            type: ActionOptionType.EXPORT,
            uri: 'REPORT_CREDIT_STATUS'
          }
        ]
      }
    ]
  };
