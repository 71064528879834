import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SereviceMessageType, SERVICE_MESSAGE, TRANS_PREFIX } from '@core/constants/serviceMessage.const';
import { IDisplayMessages, IMessages } from '@core/models/serviceMessage.model';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_ERROR_MESSAGE = 'defaultFallbackErrorMessage';

@Component({
  selector: 'app-service-message',
  templateUrl: './service-message.component.html',
  styleUrls: ['./service-message.component.scss'],
})
export class ServiceMessageComponent {

  public visible = true;
  messageTypeHeader: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public messages: IDisplayMessages,
    private dialogRef: MatDialogRef<ServiceMessageComponent>,
    private translateService: TranslateService,

  ) {


    if ([SereviceMessageType.WARNING, SereviceMessageType.ERROR].includes(this.messages.type as SereviceMessageType) && this.messages.message) {
      this.messages.message = this.translateService.instant(`${this._getTransPrefix()}${this.messages.message}`);
      if (this.messages.metaData) {
        this.messages.message = this.messages.message.replace(`{{0}}`, this.messages.metaData.toLowerCase());
      } else if (this.messages.metaDataList) {
        for (let i = 0; i < this.messages.metaDataList.length; i++) {
          this.messages.message = this.messages.message.split(`{{${i}}}`).join(this.messages.metaDataList[i]);
        }
      }
    }

    this.messageTypeHeader = this.translateService.instant(`COMMON.messageSection.${this.messages.type}`);

  }

  format(msg: IMessages) {
    if (!msg) {
      return;
    }
    msg.message = msg.message.includes('403 Forbidden') ? 'Forbidden' : msg.message;
    let formattedMsg = this.translateService.instant(`${this._getTransPrefix()}${msg.message}`);
    if (msg.metaData) {
      for (let i = 0; i < msg.metaData.length; i++) {
        formattedMsg = formattedMsg.replace(`{{${i}}}`, msg.metaData[i]);
      }
    }
    if(this.isDefaultMessageRequired(msg.message)){
      formattedMsg = this.translateService.instant(`${this._getTransPrefix()}${DEFAULT_ERROR_MESSAGE}`);
    }
    return formattedMsg;
  }

  isDefaultMessageRequired(msg) {
    if (typeof msg === 'string') {
      try {
        JSON.parse(msg);
        return true; // Successfully parsed; it's JSON-like
      } catch (e) {
        return false; // Parsing failed; not JSON-like
      }
    }
    return false;
  }

  getClassByMessageType() {
    return SERVICE_MESSAGE[this.messages.type].class;
  }

  get iconByMessageType() {
    return SERVICE_MESSAGE[this.messages.type].icon;
  }

  isWarning() {
    return this.messages.type === SereviceMessageType.WARNING;
  }

  warningResult(result) {
    this.visible = false;
    this.dialogRef.close(result);
  }

  close(): void {
    this.visible = false; // not sure if this is even needed considering the dialog ref will get destroyed
    this.dialogRef.close();
  }

  private _getTransPrefix() {
    return this.messages.transPrefix || TRANS_PREFIX;
  }

}
