import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUserData } from '@core/models/user.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';
import { shareReplay, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IOkResponse } from '@module/registration/registration.model';
import { StoreService } from '@core/store/store.service';

export const PRIVATE_USER = '/account-service/private/user';
export const NEW_PRIVATE_USER = '/account-service/private/user/new';
export const DICT_ROLES_URI = '/roles';
export const DICT_VB_ROLES_URI = '/roles/vb';
export const DICT_PROGRAMS_URI = '/account-service/lookup/standards';
export const USER_ASSOCIATION_ACTION = '/account-service/private/user/associationAction';

// OBPS
export const USER_CREATE = '/newcreate';
export const USER_UPDATE = '/newupdate';
export const SELF_UPDATE = '/selfUpdate';

// CFR
export const CFR_USER_CREATE = '/cfrCreate';
export const CFR_USER_UPDATE = '/cfrUpdate';
export const CFR_USER_SELF_UPDATE = '/cfrSelfUpdate';

// OFFSET
export const OFFSET_USER_CREATE = '/offsetCreate';
export const OFFSET_USER_UPDATE = '/offsetUpdate';
export const OFFSET_APPROVE_USER = '/doOffsetApprove';
export const OFFSET_REJECT_USER = '/doOffsetReject';
export const OFFSET_APPROVE_ASSOCIATION = '/associationApprove';
export const OFFSET_REJECT_ASSOCIATION = '/associationReject';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private readonly _roleDict$ = new BehaviorSubject<IApp.IDict[]>(null);
  private roleDict$ = this._roleDict$.asObservable();

  private readonly _programDict$ = new BehaviorSubject<IApp.IDict[]>(null);
  private programDict$ = this._programDict$.asObservable();

  constructor(private http: HttpClient,
    private store: StoreService
    ) { }

  get(userId: number): Observable<IApp.ISaveUser> {
    return this.http.get<IApp.ISaveUser>(`${ environment.apiUrl }${ NEW_PRIVATE_USER }/${ userId }`);
  }

  saveUser(userData): Observable<IOkResponse> {
    let uri = isNaN(userData.id) ?  USER_CREATE : USER_UPDATE;

    if (this.store.user.program === 'CFR') {
      uri = isNaN(userData.id) ?  CFR_USER_CREATE : CFR_USER_UPDATE;
      if (this.isSelfUpdate(userData, true)) {
        uri = CFR_USER_SELF_UPDATE;
      }
    }else{

      if (this.store.user.program === 'OFFSET') {
        uri = isNaN(userData.id) ?  OFFSET_USER_CREATE : OFFSET_USER_UPDATE;
      }
      if (this.isSelfUpdate(userData, true)) {
        uri = SELF_UPDATE;
      }

    }

    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ PRIVATE_USER }${uri}`, userData);
  }

  isSelfUpdate(userData, bypass: boolean = false){
    if(!this.checkUserdetailPresent(bypass)){
        return userData.id === this.store.user.id;
    }
  }

  checkUserdetailPresent(bypass: boolean) {
    const userDetailsPresent = this.store.user.businessTelephone ;//&& this.store.user.title;
    if (this.store.user.isCFR() || this.store.user.isOffset() || this.store.user.isOBPS()) {
      if(this.store.user.hasRole('ACCOUNT_HOLDER_ENHANCED_USER') && this.store.user.associationsList[0].accountDto ){
        if(this.store.user.associationsList[0].accountDto.status === 'PENDING_KYC'){
        return true;
        } else if(this.store.user.status === 'ACTIVE'){
          return true;
        }
      }
      return bypass ? false : userDetailsPresent &&  this.store.user.civicAddress;
    }
    return userDetailsPresent;
  }


  doAction(uri: string, userData: IApp.ISaveUser): Observable<IUserData> {
     return this.http.post<IUserData>(`${ environment.apiUrl }${ PRIVATE_USER }` + uri, userData);
   // return this.http.post<IUserData>('http://localhost:8084/api/account-service/private/user/' + uri , userData);
  }

  getRoleDict() {
    if (this._roleDict$.getValue()) {
      return this.roleDict$;
    } else {
      // const uri = this.store.user.checkIfVerificationBody() ? DICT_VB_ROLES_URI : DICT_ROLES_URI;
      const uri = DICT_ROLES_URI;
      return this.http.get<IApp.IDict[]>(`${environment.apiUrl}${PRIVATE_USER}${uri}`)
        .pipe(tap(data => this._roleDict$.next(data)));
    }
  }

  getRoleByAccount(accountId: number) {
      return this.http.get<any>(`${environment.apiUrl}${PRIVATE_USER}${DICT_ROLES_URI}?accountId=${accountId}`)
        .pipe(tap(data => data));
  }


  getProgramDict() {
    if (this._programDict$.getValue()) {
      return this.programDict$;
    } else {
      return this.http.get<IApp.IDict[]>(`${environment.apiUrl}${DICT_PROGRAMS_URI}`)
        .pipe(tap(data => this._programDict$.next(data)), shareReplay(1));
    }
  }

  setAssociationAction(data: IApp.ILinkedAccount): Observable<IApp.ILinkedAccountResponse> {
    return this.http.post(`${ environment.apiUrl }${ USER_ASSOCIATION_ACTION }`, data);
  }

  setAssociationApprovalAction(actionType: string, associationId: number): Observable<any> {
    const actionUrl = actionType.toUpperCase() === 'APPROVE' ? OFFSET_APPROVE_ASSOCIATION : OFFSET_REJECT_ASSOCIATION;
    return this.http.post(`${ environment.apiUrl }${ PRIVATE_USER }${ actionUrl }`, { id: associationId });
  }

  clearCache() {
    this._roleDict$.next(null);
    this._programDict$.next(null);
  }
}
