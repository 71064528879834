import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ProjectApplicationTableFilter } from '@core/models/filter-types.model';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { ACTIVE_ACCOUNT_ACTIONS } from '@shared/shared.const';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateNewProjectComponent } from '../create-new-project/create-new-project.component';
import { isEqual } from 'lodash';
import { PENDING_REG_KEY } from '@shared/components/project-builder/consts/project-builder.const';
const PROJECT_URL = 'project-list';
export class ProjectListComponent {
    constructor(activatedRoute, projectService, tableTemplateControls, translateService, notificationService, lookupService, helperService, modalService, router, store, cd) {
        this.activatedRoute = activatedRoute;
        this.projectService = projectService;
        this.tableTemplateControls = tableTemplateControls;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.lookupService = lookupService;
        this.helperService = helperService;
        this.modalService = modalService;
        this.router = router;
        this.store = store;
        this.cd = cd;
        this.showTableDetails = true;
        this.showFilters = true;
        this.ignoreLayout = false;
        this.isPublicView = false;
        this.projectListData = new EventEmitter();
        this._destroy$ = new Subject();
        this._stopPrevious$ = new Subject();
        this.filterFields = ProjectApplicationTableFilter;
        this.pagination = {
            page: 0,
            size: 20,
            dir: undefined,
            sort: undefined
        };
        this.firstItemIndex = 0;
        this.filterState = {};
        this.allowTransfer = true;
        this.allowCreation = true;
        this.showNextStep = false;
        this._loading = false;
        this.projectService.refreshData$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.refreshData(null));
    }
    ngOnInit() {
        if (history.state && history.state.pendingRegistration) {
            sessionStorage.setItem(PENDING_REG_KEY, '1');
        }
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.registrationAccountId = params.id;
                this.tableConfigName = 'PROJECT_APPLICATION';
                this.sourceDataType = 'ApplicationType';
                this.store.user.associationsList.forEach(asctn => {
                    if (asctn.accountDto && `${asctn.accountDto.id}` === `${this.registrationAccountId}` &&
                        ['PENDING_REVIEW_ADMIN', 'PENDING_REVIEW_ADMIN2', 'ON_HOLD'].includes(asctn.accountDto.status)) {
                        this.filterFields.customButtons = [];
                    }
                });
            }
            else {
                this.tableConfigName = this.isPublicView ? 'PUBLIC_PROJECT' : 'PROJECT';
                this.sourceDataType = 'Type';
                this.filterFields.customButtons.splice(1);
                if (!this.store.user.hasPermission('CREATE_PROJECT')) {
                    this.filterFields.customButtons = [];
                }
            }
            this.filterFields.actionModel[0].options.forEach(element => {
                element.uri = this.tableConfigName;
            });
        });
        if (!this.showTableDetails) {
            this.loadData(this.pagination, this.filterState);
        }
        this.tableConfiguration();
        this.executeActions();
    }
    executeActions() {
        this.tableTemplateControls._action
            .pipe(takeUntil(this._destroy$))
            .subscribe(metadata => {
            if (metadata.data.accountStatus === 'LOCKED' &&
                ACTIVE_ACCOUNT_ACTIONS.includes(metadata.action.workflowAction)) {
                this.showError('TransactionNotPermittedOnLockedAccount');
                return;
            }
            this.projectService.triggerActionController(metadata);
        });
    }
    showView(data) {
        let show = true;
        if (this.isPublicView) {
            return true;
        }
        if (this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
            return true;
        }
        show = false;
        this.store.user.associationsList.forEach(acc => {
            if (acc.accountId === data.accountId || acc.accountId === data.masterAccountId || data.status === 'TRANSFERRED') {
                show = true;
            }
        });
        if (data.entityActionList === null) {
            show = false;
        }
        return show;
    }
    tableConfiguration() {
        this.lookupService.getTableConfigurations(this.tableConfigName).subscribe(data => {
            this.tableConfig = data;
            const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
            const _columns = [
                {
                    expandIcon: true,
                    expandCollapse: true,
                    expandSectionData: 'subProjects',
                    expandSectionAllRecords: true,
                    field: 'id',
                    header: null,
                },
                ...this.tableConfig.columns,
                {
                    header: 'details',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.viewColTemplate,
                }, {
                    header: 'actions',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.actionTemplate,
                },
            ];
            config.expandSectionTemplate = this.expandProjectRowTemplate;
            this.filterFields = Object.assign({}, this.filterFields, { filterListsModel: data.filters });
            this.tableConfig = Object.assign({}, config, { columns: _columns });
        });
        this.translateService.onLangChange
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.loadData(this.pagination, this.filterState));
    }
    close() {
        this.router.navigate(this.helperService.getTranslatedPath('/registration-pending'));
    }
    loadData(pagination, filterState = {}) {
        if (this.registrationAccountId) {
            filterState.facilityNameList = [this.registrationAccountId];
        }
        this.loading = true;
        this._stopPrevious$.next();
        this.projectService.getProjectApplicationList(pagination, filterState)
            .pipe(takeUntil(this._destroy$), takeUntil(this._stopPrevious$)).subscribe(data => {
            this.dataList = data;
            this.projectListData.emit(data);
            if (this.registrationAccountId && data.content.length > 0) {
                this.showNextStep = true;
                this.allowCreation = `${this.registrationAccountId}` === `${data.content[0].accountId}`;
                this.allowTransfer = !this.allowCreation;
                data.content.forEach(rowrecord => {
                    const index = rowrecord.entityActionList.findIndex(o => o.workflowAction.toString() === 'CREATE_NEW_SUBMISSION');
                    if (index > -1) {
                        rowrecord.entityActionList.splice(index, 1);
                    }
                });
                if (this.allowCreation && this.filterFields.customButtons.length > 0 && this.router.url.split('/').includes(PROJECT_URL)) {
                    data.content.forEach(rowrecord => {
                        const index = rowrecord.entityActionList.findIndex(o => o.workflowAction === 'DISCARD');
                        if (index === -1) {
                            rowrecord.entityActionList.push({
                                workflowAction: 'DISCARD',
                            });
                        }
                    });
                }
                let content = data.content;
                content = content.map(project => this.projectService.filterActiveActions(project));
                this.dataList = Object.assign({}, data, { content });
            }
            this.loading = false;
        });
    }
    set loading(state) {
        this._loading = state;
        this.cd.detectChanges();
    }
    get loading() {
        return this._loading;
    }
    viewProject($event, data) {
        this.store.resetHistoryUrl();
        if (data.status === 'TRANSFERRED') {
            this.projectService.viewTransferedProject(data.type, data.id);
        }
        else {
            this.projectService.redirectToProjectScreen(null, data.id, data);
        }
    }
    createNewAProjectApplication() {
        if (this.allowCreation) {
            this.modalService.open(CreateNewProjectComponent, {
                sourceDataType: this.sourceDataType,
                registrationAccountId: this.registrationAccountId,
            });
        }
        else {
            this.showMessage();
        }
    }
    onFilterChanged(filterData, loadData = true) {
        this.firstItemIndex = 0;
        this.filterState = filterData;
        loadData && this.loadData(this.pagination, this.filterState);
    }
    onPaginationChanged(pagination) {
        if (!isEqual(pagination, this.pagination)) {
            this.pagination = pagination;
            this.firstItemIndex = (this.pagination.page * this.pagination.size);
            this.loadData(this.pagination, this.filterState);
        }
    }
    refreshData(event) {
        this.loadData(this.pagination, this.filterState);
    }
    viewAccount(event, data) { }
    onCustomButtonAction(method) {
        if (typeof this[method] === 'function') {
            this[method]();
        }
    }
    existingProjectTransfer() {
        if (this.allowTransfer) {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${this.registrationAccountId}/PROJECT_TRANSFER_INIT`), {});
        }
        else {
            this.showMessage();
        }
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
    showMessage() {
        this.notificationService.showMessage("ERROR" /* ERROR */, 'eitherTransferOrCreationAllowed');
    }
    showError(message) {
        const messages = {
            messages: [{ message }],
            type: "ERROR" /* ERROR */,
        };
        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe();
    }
}
