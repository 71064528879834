import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IEntityActionList } from '@core/models/action.model';
import { IApp } from '@core/models/app.interfaces';
import { ProjectApplicationTableFilter } from '@core/models/filter-types.model';
import { FilterModel } from '@core/models/filter.model';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { ProjectService } from '@shared/services/project.service';
import { IProjectApplication, IProjectApplicationContent } from '@shared/services/project.service.types';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';
import { ACTIVE_ACCOUNT_ACTIONS } from '@shared/shared.const';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateNewProjectComponent } from '../create-new-project/create-new-project.component';
import { isEqual } from 'lodash';
import { PENDING_REG_KEY } from '@shared/components/project-builder/consts/project-builder.const';

const PROJECT_URL = 'project-list';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit, OnDestroy {

  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  @ViewChild('expandProjectRowTemplate', {static: true}) public expandProjectRowTemplate: TemplateRef<any>;

  @Input() showTableDetails = true;
  @Input() showFilters = true;
  @Input() registrationAccountId: number;
  @Input() ignoreLayout = false;
  @Input() isPublicView = false;

  @Output() projectListData: EventEmitter<IProjectApplication> = new EventEmitter<IProjectApplication>();

  public _destroy$ = new Subject<any>();
  public _stopPrevious$ = new Subject<any>();

  filterFields = ProjectApplicationTableFilter;

  public pagination = {
    page: 0,
    size: 20,
    dir: undefined,
    sort: undefined
  } as IApp.IPagination;
  firstItemIndex = 0;
  filterState: FilterModel = {};

  tableConfig: ITableViewConfig;
  dataList: IProjectApplication;
  tableConfigName: string;
  sourceDataType: any;
  allowTransfer = true;
  allowCreation = true;
  showNextStep = false;
  _loading = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public projectService: ProjectService,
    public tableTemplateControls: TableTemplateControls,
    public translateService: TranslateService,
    public notificationService: NotificationsService,
    public lookupService: LookupService,
    public helperService: HelperService,
    public modalService: ModalService,
    public router: Router,
    public store: StoreService,
    public cd: ChangeDetectorRef) {
      this.projectService.refreshData$
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.refreshData(null));
  }

  ngOnInit() {
    if (history.state && history.state.pendingRegistration) {
      sessionStorage.setItem(PENDING_REG_KEY, '1');
    }
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.registrationAccountId = params.id;
        this.tableConfigName = 'PROJECT_APPLICATION';
        this.sourceDataType = 'ApplicationType';
        this.store.user.associationsList.forEach(asctn => {
          if (asctn.accountDto && `${asctn.accountDto.id}` === `${this.registrationAccountId}` &&
            ['PENDING_REVIEW_ADMIN', 'PENDING_REVIEW_ADMIN2', 'ON_HOLD'].includes(asctn.accountDto.status) ) {
            this.filterFields.customButtons = [];
          }
        });
      } else {
        this.tableConfigName = this.isPublicView ? 'PUBLIC_PROJECT' : 'PROJECT';
        this.sourceDataType = 'Type';
        this.filterFields.customButtons.splice(1);
        if (!this.store.user.hasPermission('CREATE_PROJECT')) {
          this.filterFields.customButtons = [];
        }
      }

      this.filterFields.actionModel[0].options.forEach(element => {
          element.uri = this.tableConfigName;
      });
    });

    if (!this.showTableDetails) {
      this.loadData(this.pagination, this.filterState);
    }

    this.tableConfiguration();

    this.executeActions();
  }

  executeActions() {
    this.tableTemplateControls._action
      .pipe(takeUntil(this._destroy$))
      .subscribe(metadata => {
        if (metadata.data.accountStatus === 'LOCKED' &&
          ACTIVE_ACCOUNT_ACTIONS.includes(metadata.action.workflowAction)) {
          this.showError('TransactionNotPermittedOnLockedAccount');
          return;
        }

        this.projectService.triggerActionController(metadata);
      });
  }

  showView(data) {
    let show = true;

    if (this.isPublicView) {
      return true;
    }

    if (this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
      return true;
    }

    show = false;
    this.store.user.associationsList.forEach(acc => {
      if (acc.accountId === data.accountId || acc.accountId === data.masterAccountId || data.status === 'TRANSFERRED') {
        show = true;
      }
    });

    if (data.entityActionList === null) {
      show = false;
    }

    return show;
  }

  tableConfiguration() {

    this.lookupService.getTableConfigurations( this.tableConfigName).subscribe(
      data => {

        this.tableConfig = data;

        const { columns, ...config } = this.tableConfig;

        const _columns: any = [
          {
            expandIcon: true,
            expandCollapse: true,
            expandSectionData: 'subProjects',
            expandSectionAllRecords: true,
            field: 'id',
            header: null,
          },
           ...this.tableConfig.columns,
          {
            header: 'details',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          }, {
            header: 'actions',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.actionTemplate,
          },
        ];
        config.expandSectionTemplate = this.expandProjectRowTemplate;
        this.filterFields = { ...this.filterFields, filterListsModel: data.filters };
        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };
      });

    this.translateService.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.loadData(this.pagination, this.filterState));
  }

  close() {
    this.router.navigate(this.helperService.getTranslatedPath('/registration-pending'));
  }

  public loadData(pagination: IApp.IPagination, filterState: FilterModel = {}) {
    if (this.registrationAccountId) {
      filterState.facilityNameList = [this.registrationAccountId];
    }
    this.loading = true;
    this._stopPrevious$.next();
    this.projectService.getProjectApplicationList(pagination, filterState)
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(
        data => {
          this.dataList = data;
          this.projectListData.emit(data);
          if (this.registrationAccountId && data.content.length > 0 ) {
            this.showNextStep = true;
            this.allowCreation = `${this.registrationAccountId}` === `${data.content[0].accountId}`;
            this.allowTransfer = !this.allowCreation;
            data.content.forEach(rowrecord => {
              const index = rowrecord.entityActionList.findIndex(
                o => o.workflowAction.toString() === 'CREATE_NEW_SUBMISSION') ;
              if (index > -1) {
                rowrecord.entityActionList.splice(index, 1);
              }
            });
            if (this.allowCreation && this.filterFields.customButtons.length > 0 && this.router.url.split('/').includes(PROJECT_URL)) {
                data.content.forEach(rowrecord => {
                  const index = rowrecord.entityActionList.findIndex(o => o.workflowAction === 'DISCARD') ;
                  if (index === -1) {
                    rowrecord.entityActionList.push({
                      workflowAction: 'DISCARD',
                    } as IEntityActionList);
                  }
                });
            }
            let content = data.content;
            content = content.map(project => this.projectService.filterActiveActions(project) as IProjectApplicationContent);
            this.dataList = {...data, content};
          }
          this.loading = false;
        },
      );
  }

  set loading(state: boolean) {
    this._loading = state;
    this.cd.detectChanges();
  }

  get loading(): boolean {
    return this._loading;
  }

  viewProject($event, data) {
    this.store.resetHistoryUrl();
    if (data.status === 'TRANSFERRED') {
      this.projectService.viewTransferedProject(data.type, data.id);
    } else {
      this.projectService.redirectToProjectScreen(null, data.id, data);
    }
  }

  createNewAProjectApplication() {
    if (this.allowCreation) {
    this.modalService.open(CreateNewProjectComponent,  {
      sourceDataType: this.sourceDataType ,
      registrationAccountId: this.registrationAccountId,
    });
    } else {
      this.showMessage();
    }
  }

  onFilterChanged(filterData: any, loadData = true) {
    this.firstItemIndex = 0;
    this.filterState = filterData;
    loadData && this.loadData(this.pagination, this.filterState);
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    if (!isEqual(pagination, this.pagination)) {
      this.pagination = pagination;
      this.firstItemIndex = (this.pagination.page * this.pagination.size);
      this.loadData(this.pagination, this.filterState);
    }
  }

  refreshData(event) {
    this.loadData(this.pagination, this.filterState);
  }

  viewAccount(event, data) { }

  onCustomButtonAction(method) {
    if (typeof this[method] === 'function') {
      this[method]();
    }
  }

  existingProjectTransfer() {
    if (this.allowTransfer) {
      this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${this.registrationAccountId}/PROJECT_TRANSFER_INIT`),
      { });
    } else {
      this.showMessage();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  showMessage() {
    this.notificationService.showMessage(SereviceMessageType.ERROR, 'eitherTransferOrCreationAllowed');
  }

  public showError(message) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      type: SereviceMessageType.ERROR,
    };
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe();
  }
}
