/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../blurb/blurb.component.ngfactory";
import * as i5 from "../../blurb/blurb.component";
import * as i6 from "../../table-filter/table-filter.component.ngfactory";
import * as i7 from "../../table-filter/table-filter.component";
import * as i8 from "../../../services/modal.service";
import * as i9 from "../../../../core/services/lookup.service";
import * as i10 from "../../../../core/store/store.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../services/helper.service";
import * as i13 from "../../../services/upload-document.service";
import * as i14 from "../../table-view/table-view.component.ngfactory";
import * as i15 from "../../table-view/table-view.component";
import * as i16 from "@angular/common";
import * as i17 from "../../../controls/generic-action-control/generic-action-control.component.ngfactory";
import * as i18 from "../../../controls/generic-action-control/generic-action-control.component";
import * as i19 from "../../../services/table-template-controls.service";
import * as i20 from "../sub-project-list/sub-project-list.component.ngfactory";
import * as i21 from "../sub-project-list/sub-project-list.component";
import * as i22 from "../../../../modules/agreement-credits/agreement-credits.service";
import * as i23 from "../../../services/project.service";
import * as i24 from "./project-list.component";
import * as i25 from "../../../services/notifications.service";
var styles_ProjectListComponent = [i0.styles];
var RenderType_ProjectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectListComponent, data: {} });
export { RenderType_ProjectListComponent as RenderType_ProjectListComponent };
function View_ProjectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.tableDetails.title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("PROJECTS_MODULE.tableDetails.message")); _ck(_v, 5, 0, currVal_1); }); }
function View_ProjectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blurb", [["class", "stick-left"]], null, null, null, i4.View_BlurbComponent_0, i4.RenderType_BlurbComponent)), i1.ɵdid(1, 114688, null, 0, i5.BlurbComponent, [i3.TranslateService], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_0 = "PROJECTS_MODULE.projectList.topMessage"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProjectListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "filters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "app-table-filter", [], null, [[null, "filterChanged"], [null, "filterInit"], [null, "customButtonAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChanged" === en)) {
        var pd_0 = (_co.onFilterChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterInit" === en)) {
        var pd_1 = (_co.onFilterChanged($event, false) !== false);
        ad = (pd_1 && ad);
    } if (("customButtonAction" === en)) {
        var pd_2 = (_co.onCustomButtonAction($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_TableFilterComponent_0, i6.RenderType_TableFilterComponent)), i1.ɵdid(2, 770048, null, 0, i7.TableFilterComponent, [i1.ViewContainerRef, i8.ModalService, i9.LookupService, i3.TranslateService, i10.StoreService, i11.Router, i12.HelperService, i13.UploadDocumentService, i11.ActivatedRoute], { fields: [0, "fields"] }, { filterChanged: "filterChanged", filterInit: "filterInit", customButtonAction: "customButtonAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterFields; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProjectListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_TableViewComponent_0, i14.RenderType_TableViewComponent)), i1.ɵdid(1, 638976, null, 0, i15.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"], relative: [2, "relative"] }, { paginationChanged: "paginationChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataList; var currVal_1 = _co.tableConfig; var currVal_2 = _co.isPublicView; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProjectListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.PROCEEED_TO_NEXT_STEP")); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_6)), i1.ɵdid(3, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-3 float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNextStep; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("COMMON.actionsLabel.BACK")); _ck(_v, 5, 0, currVal_1); }); }
function View_ProjectListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewProject($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.viewProject($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 3, 0, currVal_0); }); }
function View_ProjectListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_8)), i1.ɵdid(1, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showView(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProjectListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-action-control", [], null, null, null, i17.View_GenericActionControlComponent_0, i17.RenderType_GenericActionControlComponent)), i1.ɵdid(1, 245760, null, 0, i18.GenericActionControlComponent, [i11.Router, i12.HelperService, i8.ModalService, i19.TableTemplateControls], { rawData: [0, "rawData"], appendTo: [1, "appendTo"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "app-project-list .table-container"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ProjectListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pl-5"], ["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-sub-project-list", [], null, null, null, i20.View_SubProjectListComponent_0, i20.RenderType_SubProjectListComponent)), i1.ɵdid(2, 245760, null, 0, i21.SubProjectListComponent, [i22.AgreementCreditsService, i23.ProjectService, i19.TableTemplateControls, i3.TranslateService, i9.LookupService, i12.HelperService, i8.ModalService, i16.Location, i10.StoreService], { data: [0, "data"], tableConfigName: [1, "tableConfigName"], relative: [2, "relative"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.subProjects; var currVal_1 = _co.tableConfigName; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProjectListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { actionTemplate: 0 }), i1.ɵqud(402653184, 2, { viewColTemplate: 0 }), i1.ɵqud(402653184, 3, { expandProjectRowTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_1)), i1.ɵdid(4, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_2)), i1.ɵdid(6, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_3)), i1.ɵdid(8, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_4)), i1.ɵdid(11, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_5)), i1.ɵdid(13, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["viewColTemplate", 2]], null, 0, null, View_ProjectListComponent_7)), (_l()(), i1.ɵand(0, [[1, 2], ["actionTemplate", 2]], null, 0, null, View_ProjectListComponent_9)), (_l()(), i1.ɵand(0, [[3, 2], ["expandProjectRowTemplate", 2]], null, 0, null, View_ProjectListComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTableDetails; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.showTableDetails && !_co.isPublicView); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showFilters; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.dataList; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.showTableDetails; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_ProjectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-list", [], null, null, null, View_ProjectListComponent_0, RenderType_ProjectListComponent)), i1.ɵdid(1, 245760, null, 0, i24.ProjectListComponent, [i11.ActivatedRoute, i23.ProjectService, i19.TableTemplateControls, i3.TranslateService, i25.NotificationsService, i9.LookupService, i12.HelperService, i8.ModalService, i11.Router, i10.StoreService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectListComponentNgFactory = i1.ɵccf("app-project-list", i24.ProjectListComponent, View_ProjectListComponent_Host_0, { showTableDetails: "showTableDetails", showFilters: "showFilters", registrationAccountId: "registrationAccountId", ignoreLayout: "ignoreLayout", isPublicView: "isPublicView" }, { projectListData: "projectListData" }, []);
export { ProjectListComponentNgFactory as ProjectListComponentNgFactory };
