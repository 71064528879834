import { EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { RAW_FORM_PERMISSION_EDIT, RAW_FORM_PERMISSION_VIEW, RAW_FORM_TEXT_ONLY_FIELD, RAW_FORM_TYPE_ADDRESS_VIEW, RAW_FORM_TYPE_AGREEMENT_FACILITY_RADIO, RAW_FORM_TYPE_APP_FORMLY_DATEPICKER, RAW_FORM_TYPE_BLURB, RAW_FORM_TYPE_BUTTON, RAW_FORM_TYPE_CHECKBOX, RAW_FORM_TYPE_CHIPS, RAW_FORM_TYPE_COI_CHECKLIST_CHECKBOX_TYPE, RAW_FORM_TYPE_DICT_SELECT, RAW_FORM_TYPE_DUMMY, RAW_FORM_TYPE_DYNAMIC_TEMPLATE, RAW_FORM_TYPE_EDITABLE_TABLE, RAW_FORM_TYPE_INPUT, RAW_FORM_TYPE_INPUT_SWITCH, RAW_FORM_TYPE_MULTI_CHECKBOX, RAW_FORM_TYPE_RADIO, RAW_FORM_TYPE_RADIO_PAUL, RAW_FORM_TYPE_READONLY_INPUT, RAW_FORM_TYPE_TABLE, RAW_FORM_TYPE_TEMPLATE, RAW_FORM_TYPE_TEXTAREA, RAW_FORM_TYPE_KEY_VALUE_PAIRS, RAW_FORM_TYPE_DOCUMENTS, } from '@core/models/raw-form.constants';
import { LOOKUP_CI_METHOD } from '@core/services/lookup.service';
import { CM_ORG_PROFILE, CM_PB_GOV_PROFILE, CONTACT_PERSON, CONTACT_PERSON_MAILING_ADDRESS, CONTACT_PERSON_PHYSICAL_ADDRESS, MAILING_ADDRESS_FORM_SECTION, OFFSET_REGISTRATION_FORM, PHYSICAL_ADDRESS_FORM_SECTION } from '@shared/components/offset-account-type/offset-account-type.const';
import { OMP_ACCOUNT_TYPE } from '@shared/components/omp-account-types/ompAccountTypePurpose.const';
import { ADDRESS_FIELDS, SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { CUSTOM_VALIDATORS, postalCodeValidator } from '@shared/formly/validators';
import { map, tap } from 'rxjs/operators';
import { CARApplyPrefix, CAR_STEP_PREFIX } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/lookup.service";
import * as i2 from "../../modules/submission-management/services/submission-management.service";
import * as i3 from "../../modules/agreement-credits/agreement-credits.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../core/store/store.service";
import * as i6 from "../../modules/submission-management/modules/agrregement-to-transfer-credit/agreement-to-transfer-credit.service";
export class FormFactoryService {
    constructor(lookupService, submissionManagementService, agreementCreditsService, translateService, storeSvc, agreementToTransferCreditService) {
        this.lookupService = lookupService;
        this.submissionManagementService = submissionManagementService;
        this.agreementCreditsService = agreementCreditsService;
        this.translateService = translateService;
        this.storeSvc = storeSvc;
        this.agreementToTransferCreditService = agreementToTransferCreditService;
        this.event_callback = new EventEmitter();
        this.dictCallback = new EventEmitter();
        this.inputCallback = new EventEmitter();
        this.buttonCallback = new EventEmitter();
        this.onDateCheangeCallback = new EventEmitter();
        this.RadioCheangeCallback = new EventEmitter();
        this.fileUpload = new EventEmitter();
    }
    configureForm(rawForm) {
        let formFieldConfig = [];
        rawForm.forEach(element => {
            if (element.groupFields) {
                if (element.nestedPropertyKey) {
                    formFieldConfig = this.nestedPropertyGroupInFieldSet(element, formFieldConfig);
                }
                else {
                    if (element.subGroup) {
                        formFieldConfig.push(this.createSubGroup(element));
                    }
                    else {
                        formFieldConfig.push(this.groupInFieldSet(element));
                    }
                }
            }
            else if (element.templateField) {
                formFieldConfig.push(element.templateOptions);
            }
            else if (element.arrayField) {
                formFieldConfig.push(this.configureFieldArray(element));
            }
            else {
                formFieldConfig.push(this.groupInRow(element));
            }
        });
        return formFieldConfig;
    }
    processGroupFields(element) {
        let output;
        if (element.groupFields) {
            if (element.nestedPropertyKey) {
                output = this.nestedPropertyGroupInFieldSet(element, output instanceof Array ? output : [output]);
            }
            else {
                if (element.subGroup) {
                    output = this.createSubGroup(element);
                }
                else {
                    output = this.groupInFieldSet(element);
                }
            }
        }
        else {
            output = this.groupInRow(element);
        }
        return output;
    }
    groupInRow(rawForm) {
        return {
            className: rawForm.className || '',
            fieldGroup: this.createFieldGroup(rawForm),
            fieldGroupClassName: rawForm.fieldGroupClassName || 'row',
            hideExpression: rawForm.hideExpression || undefined,
        };
    }
    createSubGroup(rawForm) {
        const groups = [];
        rawForm.fieldGroup.forEach(entry => {
            const value = this.processGroupFields(entry);
            if (!(value instanceof Array)) {
                groups.push(value);
            }
        });
        return {
            fieldGroupClassName: rawForm.className || 'row',
            fieldGroup: groups,
        };
    }
    groupInFieldSet(rawForm) {
        let formFieldConfig;
        formFieldConfig = {
            className: rawForm.className || '',
            key: rawForm.key,
            fieldGroupClassName: 'row',
            wrappers: rawForm.wrappers || ['app-formly-fieldset'],
            templateOptions: {
                label: rawForm.label,
                translatePrefix: rawForm.translatePrefix,
                class: rawForm.class,
                icon: rawForm.icon
            },
            fieldGroup: this.createFieldGroup(rawForm),
        };
        return formFieldConfig;
    }
    nestedPropertyGroupInFieldSet(rawForm, formFieldConfig) {
        for (let index = 0; index < rawForm.personsCount; index++) {
            formFieldConfig.push({
                fieldGroupClassName: 'row',
                wrappers: ['app-formly-fieldset'],
                templateOptions: {
                    label: rawForm.label,
                    className: rawForm.className,
                },
                fieldGroup: this.createFieldGroup(rawForm, index),
            });
        }
        return formFieldConfig;
    }
    createFieldGroup(entityFields, nestedPropertyIndex) {
        const fieldRowTemp = [];
        const changeEvent = entityFields.changeExpr || false;
        entityFields.fieldGroup.forEach((entry) => {
            if (entityFields.nestedPropertyKey) {
                entry = this.getNestedPropertyKey(entry, nestedPropertyIndex);
            }
            if (entry.fieldGroup) {
                fieldRowTemp.push(this.groupInRow(entry));
            }
            else {
                fieldRowTemp.push(this.createFieldByType(entityFields.nestedPropertyKey, entry, changeEvent));
            }
        });
        return fieldRowTemp;
    }
    createFieldByType(nestedPropertyKey, field, changeEvent = false) {
        switch (field.type) {
            case RAW_FORM_TYPE_INPUT:
            case RAW_FORM_TYPE_READONLY_INPUT:
            case RAW_FORM_TYPE_TEXTAREA:
                return this.createInputTextField(nestedPropertyKey, field, changeEvent);
            case RAW_FORM_TYPE_TEMPLATE:
                return this.createTemplateField(field);
            case RAW_FORM_TYPE_DYNAMIC_TEMPLATE:
                return this.createDynamicTemplateField(field);
            case RAW_FORM_TYPE_DICT_SELECT:
                return this.createDropdownField(field, changeEvent);
            case RAW_FORM_TYPE_MULTI_CHECKBOX:
                return this.createMultiCheckBoxField(field);
            case RAW_FORM_TYPE_CHECKBOX:
                return this.createCheckboxField(field);
            case RAW_FORM_TYPE_APP_FORMLY_DATEPICKER:
                return this.createDateField(field);
            case RAW_FORM_TYPE_BUTTON:
                return this.createButton(field);
            case RAW_FORM_TYPE_CHIPS:
                return this.createChips(field);
            case RAW_FORM_TYPE_RADIO:
                return this.createRadioField(field);
            case RAW_FORM_TYPE_RADIO_PAUL:
                return this.createRadioFieldPaul(field);
            case RAW_FORM_TYPE_COI_CHECKLIST_CHECKBOX_TYPE:
                return this.createCoiChecklistCheckboxType(field);
            case RAW_FORM_TYPE_AGREEMENT_FACILITY_RADIO:
                return this.agreementFacilityRadio(field);
            case RAW_FORM_TYPE_DUMMY:
                return this.dummyField(field);
            case RAW_FORM_TYPE_INPUT_SWITCH:
                return this.createInputSwitchField(field);
            case RAW_FORM_TYPE_BLURB:
                return this.blurbField(field);
            case RAW_FORM_TYPE_EDITABLE_TABLE:
                return this.editableTable(field);
            case RAW_FORM_TEXT_ONLY_FIELD:
                return this.createtextOnlyField(field);
            case RAW_FORM_TYPE_TABLE:
                return this.table(field);
            case RAW_FORM_TYPE_ADDRESS_VIEW:
                return this._setAddressView(field);
            case RAW_FORM_TYPE_KEY_VALUE_PAIRS:
                return this._setKeyValuePairs(field);
            case RAW_FORM_TYPE_DOCUMENTS:
                return this._setDocuments(field);
        }
    }
    agreementFacilityRadio(field) {
        const fieldTemplate = {
            type: RAW_FORM_TYPE_AGREEMENT_FACILITY_RADIO,
            key: field.key,
            className: field.className,
            templateOptions: {
                options: field.options,
                disabled: field.disabled,
                required: field.required,
                hideExpression: field.hideExpression,
                data: field.data,
            },
        };
        return fieldTemplate;
    }
    createCoiChecklistCheckboxType(field) {
        const fieldTemplate = {
            type: RAW_FORM_TYPE_COI_CHECKLIST_CHECKBOX_TYPE,
            key: field.key,
            className: field.className,
            hideExpression: field.hideExpression,
            templateOptions: {
                options: field.options,
                required: field.required,
                data: field.data,
                disabled: field.disabled,
            },
        };
        return fieldTemplate;
    }
    createRadioFieldPaul(field) {
        const fieldTemplate = {
            type: 'coi-radio-type',
            key: field.key,
            className: field.className,
            hideExpression: field.hideExpression,
            templateOptions: {
                options: field.options,
                required: field.required,
                data: field.data,
                disabled: field.disabled,
            },
        };
        return fieldTemplate;
    }
    createRadioField(field) {
        const fieldTemplate = {
            id: field.id,
            type: field.type,
            wrappers: field.wrappers,
            key: field.key,
            name: field.name || field.id,
            className: field.className,
            hideExpression: field.hideExpression,
            defaultValue: field.defaultValue,
            templateOptions: {
                required: field.required,
                options: field.apiOptions ? this.getLookUpDataForSource(field) : field.options,
                label: field.label,
                data: field.data,
                disabled: field.disabled,
                itemLabelPrefix: field.itemLabelPrefix,
                dummyInputForLabel: field.dummyInputForLabel,
                change: typeof field.onChange === 'function' ? (fld, event) => field.onChange(fld, event) : fld => this.RadioCheangeCallback.emit(fld),
            },
            expressionProperties: field.expressionProperties || {},
        };
        if (field.requiredExpression) {
            fieldTemplate.expressionProperties['templateOptions.required'] = field.requiredExpression;
        }
        return fieldTemplate;
    }
    dummyField(field) {
        const fieldTemplate = {
            type: field.type,
            wrappers: field.wrappers,
            className: field.className,
        };
        return fieldTemplate;
    }
    blurbField(field) {
        const fieldTemplate = {
            type: field.type,
            wrappers: field.wrappers,
            className: field.className,
            templateOptions: {
                label: field.label,
                translate: !!field.translate,
            },
        };
        return fieldTemplate;
    }
    editableTable(field) {
        const customTemplateOptions = field.customTemplateOptions || {};
        return {
            type: field.type,
            id: field.id,
            key: field.key,
            className: field.className || 'col-12',
            hideExpression: field.hideExpression,
            validators: field.validators || {},
            templateOptions: Object.assign({}, customTemplateOptions, { controlId: field.id }),
        };
    }
    table(field) {
        const customTemplateOptions = field.customTemplateOptions || {};
        const fieldTemplate = {
            type: field.type,
            id: field.id,
            key: field.key,
            hideExpression: field.hideExpression,
            className: field.className || 'col-12',
            templateOptions: Object.assign({}, customTemplateOptions, { controlId: field.id }),
        };
        return fieldTemplate;
    }
    _setAddressView(field) {
        return {
            type: field.type,
            id: field.id,
            key: field.key,
            hideExpression: field.hideExpression,
            className: field.className || 'col-12',
            templateOptions: {
                label: field.label,
                legend: field.data.legend,
                addressKey: field.data.addressKey,
            }
        };
    }
    _setKeyValuePairs(field) {
        const customTemplateOptions = field.customTemplateOptions || {};
        return {
            type: field.type,
            id: field.id,
            key: field.key,
            hideExpression: field.hideExpression,
            className: field.className || 'col-12',
            templateOptions: Object.assign({}, customTemplateOptions, { translatePrefix: field.translatePrefix, keyValueConfig: field.keyValueConfig, controlId: field.id, change: typeof field.onChange === 'function' ? (fld, event) => field.onChange(fld, event) : null }),
        };
    }
    _setDocuments(field) {
        const customTemplateOptions = field.customTemplateOptions || {};
        return {
            type: field.type,
            id: field.id,
            key: field.key,
            hideExpression: field.hideExpression,
            className: field.className || 'col-12',
            templateOptions: Object.assign({}, customTemplateOptions, { onDocumentsChange: field.onDocumentsChange, documentUploadConfig: field.documentUploadConfig }),
        };
    }
    getLookUpDataForSource(field, translate = false, keepTranslationPrefix = true) {
        return field.dataType
            ? this.lookupService.getLookUpData(`${field.apiService}${field.dataType}`, false)
            : this.lookupService.getLookUpData(field.apiService, translate, keepTranslationPrefix, field.bindValue, field.bindLabel);
    }
    createCheckboxField(field, changeEvent = false) {
        const fieldTemplate = {
            className: field.className,
            type: 'checkbox',
            wrappers: ['form-field'],
            key: field.key,
            defaultValue: field.defaultValue,
            hideExpression: field.hideExpression,
            id: field.id,
            templateOptions: {
                label: field.label,
                required: field.required,
                disabled: field.disabled,
                translatePrefix: field.translatePrefix,
                translateOptions: true,
                translate: typeof field.translate === 'boolean' ? field.translate : true,
                skipFirstChange: false,
                change: field.change ? field.change : (field, event) => this.event_callback.emit(field),
            },
            expressionProperties: field.expressionProperties || {},
            validators: {},
        };
        if (changeEvent) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', changeEvent).bind(this);
        }
        else if (field.changeExpr) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', field.changeExpr).bind(this);
        }
        if (field.requiredTrue) {
            fieldTemplate.validators['validation'] = [Validators.requiredTrue];
            fieldTemplate.templateOptions.skipFirstChange = true;
        }
        if (field.disabledExpression) {
            fieldTemplate.expressionProperties['templateOptions.disabled'] = field.disabledExpression;
        }
        if (field.requiredExpression) {
            fieldTemplate.expressionProperties['templateOptions.required'] = field.requiredExpression;
        }
        return fieldTemplate;
    }
    createInputTextField(nestedPropertyKey, field, changeEvent = false) {
        const onChange = typeof field.customTemplateOptions === 'object' ? field.customTemplateOptions.onChange : null;
        const defaultExpressionProperties = {
            'templateOptions.tabindex': (model, formState, fld) => {
                if (field.hideExpression && formState.hidden) {
                    return -1;
                }
                else {
                    return 0;
                }
            },
        };
        const fieldTemplate = {
            id: field.id,
            key: nestedPropertyKey ? field.nestedKey : field.key,
            type: field.type,
            expressionProperties: Object.assign({}, defaultExpressionProperties, (field.expressionProperties || {})),
            className: field.className ? field.className : 'col-6' + (field.hidden ? ' hidden' : ''),
            hideExpression: field.hideExpression,
            templateOptions: Object.assign({}, field.customTemplateOptions, { allowNegativeNumbers: typeof field.allowNegativeNumbers === 'boolean' ? field.allowNegativeNumbers : true, chips: field.chips, customTemplateOptions: field.customTemplateOptions, defaultValue: field.defaultValue, disabled: field.disabled, dummyLabel: field.dummyLabel, formDisabled: field.formDisabled, icon: field.icon, ignoreLocalFormDisabled: field.ignoreLocalFormDisabled, label: field.label, onChange: typeof onChange === 'function' ? onChange : undefined, placeholder: field.placeholder, required: field.required, rows: field.rows ? field.rows : 3, translate: typeof field.translate === 'boolean' ? field.translate : true, translateOptions: true, translatePrefix: field.translatePrefix, type: field.customTemplateOptions && field.customTemplateOptions.type, tabindex: field.hideExpression && -1, min: field.min, max: field.max, dataPopup: field.dataPopup }),
            validators: {},
            parsers: field.parsers,
            modelOptions: field.modelOptions,
        };
        if (field.validators) {
            field.validators.forEach(validator => {
                fieldTemplate['validators'][validator] = {
                    expression: c => CUSTOM_VALIDATORS[validator].expression(c),
                    message: this.translateService.instant(CUSTOM_VALIDATORS[validator].message),
                };
            });
        }
        if (field.minLength) {
            fieldTemplate.validators['validation'] = [Validators.minLength(field.minLength)];
        }
        if (field.maxLength) {
            fieldTemplate.validators['validation'] = [Validators.maxLength(field.maxLength)];
        }
        if ((field.max || field.max === 0) || (field.min || field.min === 0)) {
            fieldTemplate.validators['validation'] = [Validators.max(field.max), Validators.min(field.min)];
        }
        if (changeEvent) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', changeEvent).bind(this);
        }
        else if (field.changeExpr) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', field.changeExpr).bind(this);
        }
        else if (field.keyup) {
            fieldTemplate.templateOptions['keyup'] = Function('field', '$event', field.keyup).bind(this);
        }
        if (field.disabledExpression) {
            fieldTemplate.expressionProperties['templateOptions.disabled'] = field.disabledExpression;
        }
        if (field.requiredExpression) {
            fieldTemplate.expressionProperties['templateOptions.required'] = field.requiredExpression;
        }
        return fieldTemplate;
    }
    createDateField(field) {
        return {
            className: field.className ? field.className : 'col-6',
            type: field.type,
            key: field.key,
            hideExpression: field.hideExpression,
            id: field.id,
            wrappers: ['form-field'],
            templateOptions: Object.assign({}, (field.templateOptions || {}), { label: field.label, required: field.required, disabled: field.disabled, translatePrefix: field.translatePrefix, translateOptions: true, placeholder: field.placeholder, tooltip: field.tooltip, change: typeof field.onChange === 'function' ? (fld, event) => field.onChange(fld, event) : fld => this.onDateCheangeCallback.emit(fld) }),
            expressionProperties: field.expressionProperties,
        };
    }
    createTemplateField(field) {
        const defs = {
            id: field.id,
            key: field.key,
            className: field.className ? field.className : 'col-6',
            hideExpression: field.hideExpression,
        };
        if (field.parse) {
            return Object.assign({}, defs, { wrappers: field.wrappers, type: 'parsed-template', templateOptions: {
                    template: field.label,
                    id: field.id,
                    translate: field.translate,
                    class: field.class || '',
                    icon: field.icon,
                } });
        }
        return Object.assign({}, defs, { template: field.label });
    }
    createDynamicTemplateField(field) {
        const defs = {
            id: field.id,
            key: field.key,
            className: field.className ? field.className : 'col-6',
            hideExpression: field.hideExpression,
        };
        if (field.parse) {
            return Object.assign({}, defs, { type: 'dynamic-template', templateOptions: {
                    id: field.id,
                    template: field.label,
                    translate: field.translate,
                    updateContent: field.updateContent,
                } });
        }
        return Object.assign({}, defs, { template: field.label, templateOptions: {
                updateContent: field.updateContent,
            } });
    }
    getNestedPropertyKey(field, index) {
        field.nestedKey = field.parentKey + '[' + index + '].' + field.key;
        return field;
    }
    createButton(field) {
        const onClick = typeof field.customTemplateOptions === 'object' ? field.customTemplateOptions.onClick : null;
        const btnType = typeof field.customTemplateOptions === 'object' ? field.customTemplateOptions.btnType : undefined;
        const className = typeof field.className === 'string' ? field.className : 'col-2';
        const fieldTemplate = {
            type: RAW_FORM_TYPE_BUTTON,
            className,
            hideExpression: field.hideExpression,
            templateOptions: {
                text: field.label,
                btnType: btnType || 'secondary',
                disabled: field.disabled,
                onClick: typeof onClick === 'function'
                    ? ($event, form) => onClick($event, form)
                    : ($event) => this.buttonCallback.emit({ field, event: $event }),
            },
            expressionProperties: {},
        };
        if (field.disabledExpression) {
            fieldTemplate.expressionProperties['templateOptions.disabled'] = field.disabledExpression;
        }
        return fieldTemplate;
    }
    createChips(field, changeEvent = false) {
        const fieldTemplate = {
            className: field.className ? field.className : 'col-6',
            type: RAW_FORM_TYPE_CHIPS,
            key: field.key,
            id: field.id,
            hideExpression: field.hideExpression,
            templateOptions: {
                label: field.label,
                translatePrefix: field.translatePrefix,
                translateOptions: true,
                placeholder: field.placeholder ? field.placeholder : 'selectItem',
                required: field.required,
                disabled: field.disabled,
                buttonText: field.buttonText,
                maxLength: field.maxLength,
                change: (fld, event) => {
                    if (field.onChange) {
                        field.onChange(fld, event);
                    }
                    this.dictCallback.emit(fld);
                },
            },
            expressionProperties: {},
        };
        if (field.expressionProperties) {
            fieldTemplate.expressionProperties = field.expressionProperties;
        }
        return fieldTemplate;
    }
    createDropdownField(field, changeEvent) {
        const fieldTemplate = {
            className: field.className ? field.className : 'col-6',
            type: RAW_FORM_TYPE_DICT_SELECT,
            wrappers: ['form-field'],
            key: field.key,
            defaultValue: field.defaultValue,
            id: field.id,
            hideExpression: !this.checkPermission(RAW_FORM_PERMISSION_VIEW, field) || this.hideEvaluation(field),
            templateOptions: {
                source: field.source ? this.getDataSource(field.source, field.dataType) : null,
                skipItems: field.skipItems,
                items: field.items,
                label: field.label,
                dataProvided: field.dataProvided,
                translatePrefix: field.translatePrefix,
                translateOptions: !field.ignoreTranslateOptions,
                translate: field.translate !== false,
                placeholder: field.placeholder ? field.placeholder : 'selectItem',
                required: field.required,
                disabled: !this.checkPermission(RAW_FORM_PERMISSION_EDIT, field) || field.disabled,
                bindValue: field.bindValue,
                bindLabel: field.bindLabel,
                searchable: field.searchable === undefined ? true : field.searchable,
                formDisabled: field.formDisabled,
                multiple: field.multiple,
                appendTo: field.appendTo || 'body',
                ignoreDropdownAppend: field.ignoreDropdownAppend,
                ignorePlaceholderTranslatePrefix: field.ignorePlaceholderTranslatePrefix,
                sort: field.sort !== false,
                filter: field.filter,
                sortByLang: field.sortByLang,
                freeTextExpr: field.freeTextExpr,
                groupBy: field.groupBy,
                clearable: field.clearable,
                reload$: field.reload$,
                mapMissingFn: field.mapMissingFn,
                virtualScroll: field.virtualScroll,
                onDataLoad: field.onDataLoad,
                skipInitialBlur: field.skipInitialBlur,
                change: typeof field.onChange === 'function'
                    ? (data, event) => {
                        data.event = event;
                        field.onChange(data);
                    }
                    : (data, event) => {
                        data.event = event;
                        this.dictCallback.emit(data);
                    },
                onSelect: field.onSelect,
                preventAutoPopulate: field.preventAutoPopulate,
                ignoreLocalFormDisabled: field.ignoreLocalFormDisabled,
                reloadSourceOnLangChange: field.reloadSourceOnLangChange,
                toolTip: field.icon,
            },
            expressionProperties: field.expressionProperties || {},
        };
        if (changeEvent) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', `${changeEvent}; this.dictCallback.emit(field);`).bind(this);
        }
        else if (field.changeExpr) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', `${field.changeExpr}; this.dictCallback.emit(field);`).bind(this);
        }
        if (field.disabledExpression) {
            fieldTemplate.expressionProperties['templateOptions.disabled'] = field.disabledExpression;
        }
        if (field.requiredExpression) {
            fieldTemplate.expressionProperties['templateOptions.required'] = field.requiredExpression;
        }
        return fieldTemplate;
    }
    hideEvaluation(field) {
        if ((field.items === undefined || field.items === null) && (field.source === undefined || field.source === null)) {
            return true;
        }
        return field.hideExpression;
    }
    // TODO
    createMultiCheckBoxField(field) {
        const fieldTemplate = {
            className: field.className ? field.className : 'col-12',
            type: RAW_FORM_TYPE_MULTI_CHECKBOX,
            wrappers: ['form-field'],
            key: field.key,
            id: field.id,
            defaultValue: field.defaultValue,
            hideExpression: field.hideExpression,
            templateOptions: {
                source: typeof field.source === 'object' ? field.source : this.getDataSource(field.source, field.dataType),
                label: field.label,
                className: 'col-6',
                translatePrefix: field.translatePrefix,
                translateOptions: true,
                dummyInputForLabel: field.dummyInputForLabel,
                dummyLabel: field.dummyLabel,
                placeholder: 'selectItem',
                itemLabelPrefix: field.itemLabelPrefix,
                required: field.required,
                disabled: field.disabled,
                inline: field.inline || false,
                change: field.change,
                bindLabel: field.bindLabel,
                reload$: field.reload$,
                skipSort: field.skipSort || false,
            },
            expressionProperties: {},
            validation: field.validation,
        };
        if (field.expressionProperties) {
            fieldTemplate.expressionProperties = field.expressionProperties;
        }
        if (field.requiredExpression) {
            fieldTemplate.expressionProperties['templateOptions.required'] = field.requiredExpression;
        }
        if (field.changeExpr) {
            fieldTemplate.templateOptions['change'] = Function('field', '$event', `${field.changeExpr};`).bind(this);
        }
        return fieldTemplate;
    }
    getDataSource(dataType, type) {
        if (typeof dataType === 'object') {
            return dataType;
        }
        switch (dataType) {
            case SOURCE_DATA_TYPE.BUSSINES_STRUCTURE:
                return this.lookupService.getBusinessStructure(type);
            case SOURCE_DATA_TYPE.COUNTRY:
                return this.lookupService.getCountries();
            case SOURCE_DATA_TYPE.ACTIVITY_COUNTRY:
                return this.lookupService.getActivityCountries(type);
            case SOURCE_DATA_TYPE.PROVINCE:
                return this.lookupService.getJurisdictions();
            case SOURCE_DATA_TYPE.ALL_PROVINCE:
                return this.lookupService.getAllProvince();
            case SOURCE_DATA_TYPE.OUTSIDE_CA_PROVINCE:
                return this.lookupService.getOutsideCAProvince();
            case SOURCE_DATA_TYPE.AREA_OF_EXPERTISE:
                return this.lookupService.getAreaOfExpertise(type);
            case SOURCE_DATA_TYPE.AREA_OF_EXPERTISE_BY_ID:
                return this.lookupService.getAreaOfExpertiseById(type);
            case SOURCE_DATA_TYPE.PROFESSIONAL_QUALIFICATIONS:
                return this.lookupService.getProfessionalQualifications();
            case SOURCE_DATA_TYPE.ACCREDITATION_BODY:
                return this.lookupService.getAccreditationTypes();
            case SOURCE_DATA_TYPE.ORGANIZATION:
                return this.lookupService.getRegistrationFacilities(type);
            case SOURCE_DATA_TYPE.ORGANIZATION_WITHOUT_AGREEMENT:
                return this.lookupService.getRegistrationFacilitiesWithoutagreement(type);
            case SOURCE_DATA_TYPE.SUB_ORGANIZATION:
                return this.lookupService.getSubOrganizations(type);
            case SOURCE_DATA_TYPE.ADDRESS:
                return this.lookupService.getAddressList(type);
            case SOURCE_DATA_TYPE.FUELS:
                return this.lookupService.getFuels(type);
            case SOURCE_DATA_TYPE.APPLICATION_SUBMISSION_TYPE:
                return this.lookupService.getApplicationSubmissionTypes(type);
            case SOURCE_DATA_TYPE.ACCOUNT_NAMES:
                return this.lookupService.getFacilityNames();
            case SOURCE_DATA_TYPE.DELIVERY_MEDIUM:
                return this.lookupService.getDeliveryTypes();
            case SOURCE_DATA_TYPE.QUANTIFICATION_METHOD:
                return this.lookupService.getQuantificationMethods();
            case SOURCE_DATA_TYPE.CIMETHOD:
                return this.lookupService.getLookUpData(LOOKUP_CI_METHOD);
            case SOURCE_DATA_TYPE.STATIC_DATA:
                return this.lookupService.getStaticData(type);
            case SOURCE_DATA_TYPE.FEED_STOCK:
                return this.lookupService.getFeedStock();
            case SOURCE_DATA_TYPE.STAGE_OF_PRODUCTION:
                return this.lookupService.getStageOfProduction();
            case SOURCE_DATA_TYPE.THERMAL_ELECTRICAL_COMPRESSION:
                return this.lookupService.getThermalElectricalCompression();
            case SOURCE_DATA_TYPE.REGULATORY_REGIME:
                return this.lookupService.getRegulatoryRegimes();
            case SOURCE_DATA_TYPE.VERIFICATION_BODY:
                return this.submissionManagementService.getVerificationBodyList({ size: -1 }).pipe(map(data => {
                    return data.content.map(content => ({
                        id: content.id,
                        name: content.legalName,
                    }));
                }));
            case SOURCE_DATA_TYPE.PREVIOUS_OPA:
                return this.agreementCreditsService.getAgreementList({ size: -1 }, { registrationAccountId: type }).pipe(map(data => {
                    return data.content
                        .filter(t => t.opaAccount.id && t.status !== 'IN_PROCESS' && t.status !== 'DISCARDED' && t.status !== 'REJECTED')
                        .map(content => ({
                        id: content.id,
                        name: content.nameIdentifier,
                    }));
                }));
            case SOURCE_DATA_TYPE.PREVIOUS_OPA_ACCOUNT:
                return this.lookupService.searchPreviousOpas(type);
            case SOURCE_DATA_TYPE.APPLICATION_SUBMISSION_ACTIVITY_TYPE:
                return this.lookupService.getApplicationSubTypes(type);
            case SOURCE_DATA_TYPE.COMPLIANCE_PERIOD:
                return this.lookupService.getCompliancePeriods();
            case SOURCE_DATA_TYPE.CFR_COMPLAINCE_PERIOD:
                return this.lookupService.getCfrCompliancePeriod();
            case SOURCE_DATA_TYPE.ELECTRIC_ENERGEY_SOURCE:
                return this.lookupService.geElectricEnergySource();
            case SOURCE_DATA_TYPE.ACTIVITY_SUBJECT_TO_APPLICATION:
                return this.lookupService.getActivitySubjectToApplication();
            case SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY:
                return this.lookupService.getTypeOfEnergy();
            case SOURCE_DATA_TYPE.PROVINCIAL_GRID:
                return this.lookupService.getElectricityFromProvincialGrid();
            case SOURCE_DATA_TYPE.ACCOUNT_TYPE:
                return this.lookupService.getAccountSubTypes(type);
            case SOURCE_DATA_TYPE.LANGUAGE_OF_CORRESPONDANCE:
                return this.lookupService.getLanguageOfCorrespondance();
            case SOURCE_DATA_TYPE.GREEN_HOUSE_GAS:
                return this.lookupService.getOffsetGreenHousGases();
            case SOURCE_DATA_TYPE.OFFSET_PROTOCOLS:
                return this.lookupService.getOffsetProtocols();
            case SOURCE_DATA_TYPE.PROVINCE_BY_COUNTRY:
                return this.lookupService.getProvinceByCountry();
            case SOURCE_DATA_TYPE.PROJECT_TYPE:
                return this.lookupService.getPojectType(type);
            case SOURCE_DATA_TYPE.SUB_ACCOUNT_REASON:
                return this.lookupService.getSubAccountCreationReasons();
            case SOURCE_DATA_TYPE.MASTER_ACCOUNT:
                return this.lookupService.getMasterAccounts();
            case SOURCE_DATA_TYPE.SEARCH_PROJECT_BY_ACCOUNT:
                return this.lookupService.getOffsetProjectNames(type);
            case SOURCE_DATA_TYPE.PROJECT_CANCELLATION_REASON:
                return this.lookupService.getProjectCancellationReason();
            case SOURCE_DATA_TYPE.SUBMISSION_TYPES_LIST:
                return this.lookupService.getSubmissionTypesList$();
            case SOURCE_DATA_TYPE.SUBMISSION_REPORT_TYPE_LIST:
                return this.lookupService.getSubmissionReportTypesList$();
            case SOURCE_DATA_TYPE.ACTIVE_ACCOUNT_NAMES:
                return this.lookupService.getFacilityNames().pipe(map(accounts => accounts.filter(account => account.status === 'ACTIVE')));
            case SOURCE_DATA_TYPE.ERP_PROJECT_TYPE:
                return this.lookupService.getERPProjectType();
            case SOURCE_DATA_TYPE.ERP_QUANTIFICATION_METHODS:
                return this.lookupService.getERPQuantificationMethods(type);
            case SOURCE_DATA_TYPE.ERP_APPROVAL_TEST_METHOD:
                return this.lookupService.getERPApprovalTestMethods();
            case SOURCE_DATA_TYPE.QM_STATUS_LIST:
                return this.lookupService.getQMStatus();
            case SOURCE_DATA_TYPE.QM_FUEL_TYPES:
                return this.lookupService.getQMFuelTypes();
            case SOURCE_DATA_TYPE.CCR_TYPE:
                return this.lookupService.getCCRTypes();
            case SOURCE_DATA_TYPE.CCR_ACTIVITY_TYPE:
                return this.lookupService.getCCRActivityType(type);
            case SOURCE_DATA_TYPE.CCR_COMPLIANCE_YEARS:
                return this.lookupService.getCCRComplianceYears();
            case SOURCE_DATA_TYPE.CCR_QUARTERS:
                return this.lookupService.getCCRQuarters(type);
            case SOURCE_DATA_TYPE.CARBON_INTENSITY_IDS:
                return this.agreementToTransferCreditService.carbonIntensityIds$;
            case SOURCE_DATA_TYPE.AGREEMENT_TRANSFER_CREDIT_FUELS:
                return this.lookupService.getAgreementTransferCreditFuels(type).pipe(tap(data => this.agreementToTransferCreditService.fuelList = data));
            case SOURCE_DATA_TYPE.CI_APPLICATION_TYPE:
                return this.lookupService.getCiApplicationTypes();
            case SOURCE_DATA_TYPE.SECTION_FOR_CREDIT_CREATION:
                return this.lookupService.getSectionForCreditCreation();
            case SOURCE_DATA_TYPE.ENERGY_EFFICIENCY_FOR_ANNUAL_STEP:
                return this.lookupService.getEnergyEfficiencyForAnnualStep(type);
            case SOURCE_DATA_TYPE.NOE_NATURE_OF_ERRORS:
                return this.lookupService.getNOENatureOfErrors();
            case SOURCE_DATA_TYPE.NOE_TYPE_OF_ACCOUNTS_FOR_NOTICE:
                return this.lookupService.getNOETypeOfAccountsForNotice();
            case SOURCE_DATA_TYPE.AREA_OF_EXPERTISE_VB:
                return this.lookupService.getAreaOfExpertiseForVB();
            case SOURCE_DATA_TYPE.COMPLIANCE_REPORT_COMPLIANCE_YEARS:
                return this.lookupService.getComplianceReportComplianceYears();
            case SOURCE_DATA_TYPE.CAR_COMPLIANCE_YEARS:
                return this.lookupService.getCARComplianceYears();
            case SOURCE_DATA_TYPE.CREDIT_BALANCE_REPORT_COMPLIANCE_YEARS:
                return this.lookupService.getCreditBalanceReportYears();
            case SOURCE_DATA_TYPE.CCM_COMPLAINCE_PERIOD:
                return this.lookupService.getComplianceYearCCM();
            case SOURCE_DATA_TYPE.CREDIT_REVENUE_REPORT_COMPLIANCE_YEARS:
                return this.lookupService.getCCRReportYears();
            case SOURCE_DATA_TYPE.COMPLIMENTARY_COMPLIANCE_REPORT_COMPLIANCE_YEARS:
                return this.lookupService.getCompComplianceReportComplianceYears();
        }
    }
    getForm(form, path, field = false) {
        const targetKeys = path.split('.');
        const control = field ? targetKeys.pop() : null;
        let targetForm = form.root;
        targetKeys.forEach(key => {
            const _form = targetForm || { controls: {} };
            targetForm = _form.controls[key];
        });
        return targetForm ? (field ? targetForm.controls[control] : targetForm) : null;
    }
    getConditionVal($event, conditionField) {
        const targetGroup = conditionField.split(',');
        if (targetGroup.length > 1) {
            let value = true;
            targetGroup.forEach(path => {
                const control = this.getForm($event.form, path.trim(), true);
                value = value && control && control.value;
            });
            return value;
        }
        return conditionField ? this.getForm($event.form, conditionField, true) : true;
    }
    cloneField(field, $event, targetGroupKey, conditionField) {
        const targetForm = this.getForm($event.form, targetGroupKey);
        const condVal = this.getConditionVal($event, conditionField);
        const value = typeof condVal === 'boolean' ? condVal : condVal ? condVal.value : false;
        if (value === true && targetForm.controls[$event.key] !== undefined) {
            targetForm.controls[$event.key].setValue($event.form.controls[$event.key].value);
        }
    }
    cloneSingleField(field, $event) {
        field.formId = $event.id;
        this.inputCallback.emit(field);
    }
    disableForm($event, field, path, fieldsList) {
        const targetForm = path ? this.getForm(field.form, path) : field.form;
        const value = field.form.get(field.key) ? field.form.get(field.key).value : false;
        let _fields = null;
        if (field.form.get(field.key) && field.form.get(field.key).disabled) {
            return;
        }
        if (typeof fieldsList === 'string') {
            const form = this.getForm(field.form, fieldsList);
            _fields = form && Object.keys(form.controls);
        }
        else if (fieldsList && fieldsList.length > 0) {
            _fields = fieldsList;
        }
        Object.keys(targetForm.controls).forEach(controlKey => {
            if (controlKey !== field.key.split('.').pop() && (_fields ? _fields.includes(controlKey) : true)) {
                value ? targetForm.controls[controlKey].disable() : targetForm.controls[controlKey].enable();
            }
        });
    }
    disableAddressCheckbox(field, conditionField, value, targetField) {
        const form = field.form;
        const values = value.split(',');
        const srcField = this.getForm(form, conditionField, true);
        const trgField = this.getForm(form, targetField, true);
        if (values.some(v => srcField.value === v)) {
            if (trgField.value === true) {
                trgField.setValue(false);
                Object.keys(trgField.parent.controls).forEach(key => trgField.parent.get(key).enable());
            }
            trgField.disable();
        }
        else {
            trgField.enable();
        }
    }
    disableSameAsPostalCheckbox(field, postalAddressForm, targetField) {
        const IGNORED_FIELDS = ['sameAsOrganizationAddress'];
        const form = field.form;
        const postalForm = this.getForm(form, postalAddressForm);
        const trgField = this.getForm(form, targetField, true);
        let disableState = true;
        Object.keys(postalForm.controls).forEach(key => {
            const val = postalForm.controls[key].value;
            if (!IGNORED_FIELDS.includes(key) && val !== null && val !== ''
                && !(key === 'country' && postalForm.controls[key].disabled)) {
                disableState = false;
            }
        });
        if (disableState) {
            if (trgField.value === true) {
                trgField.setValue(false);
                Object.keys(trgField.parent.controls).forEach(key => trgField.parent.get(key).enable());
            }
            trgField.disable();
        }
        else {
            trgField.enable();
        }
    }
    cloneFormGroup($event, field, srcGroupKey, targetGroupKey, fields = null) {
        const IGNORED_FIELDS = ['sameAsOrganizationAddress', 'sameAsPostalAddress', 'sameAsPhysicalAddress'];
        const isFieldEnabled = f => {
            return f.constructor.name === 'FormControl' ? f.enabled : true;
        };
        if ($event.skipCopy) {
            return;
        }
        const srcFormGroup = this.getForm(field.form, srcGroupKey);
        const targetFormGroup = this.getForm(field.form, targetGroupKey);
        const value = field.form.get(field.key) ? field.form.get(field.key).value : null;
        if (srcFormGroup && targetFormGroup) {
            Object.keys(srcFormGroup.controls).forEach(controlKey => {
                if (!IGNORED_FIELDS.includes(controlKey) &&
                    targetFormGroup.controls[controlKey] &&
                    (fields !== null ? fields.includes(controlKey) : true) &&
                    isFieldEnabled(targetFormGroup.controls[controlKey])) {
                    if (value && srcFormGroup.controls[controlKey].value) {
                        targetFormGroup.controls[controlKey].setValue(srcFormGroup.controls[controlKey].value);
                    }
                    else if ($event.initial) {
                        targetFormGroup.controls[controlKey].setValue(targetFormGroup.controls[controlKey].value);
                    }
                    else {
                        targetFormGroup.controls[controlKey].reset();
                    }
                    if (controlKey === 'country' && field.model[controlKey] === null && targetFormGroup.controls[controlKey].value) {
                        field.model[controlKey] = targetFormGroup.controls[controlKey].value;
                    }
                }
            });
        }
    }
    checkFormGroupForChanges($event, field, fieldKey, srcGroupKey, targetGroupKey, fields = null) {
        const targetFormGroup = this.getForm(field.form, targetGroupKey);
        const value = targetFormGroup.get(fieldKey).value;
        if (value) {
            this.cloneFormGroup($event, field, srcGroupKey, targetGroupKey, (fields = null));
        }
    }
    configurFormPostWithCivicAddress(POSTAL, CIVIC, hiddenDataKey = null) {
        const addresses = [];
        if (POSTAL) {
            const postalAddress = this.getAddressFromConfig(POSTAL);
            addresses.push(postalAddress);
        }
        if (CIVIC) {
            const civicAddress = this.getAddressFromConfig(CIVIC);
            addresses.push(civicAddress);
        }
        if (hiddenDataKey) {
            const hiddenAddress = this.getAddressFromConfig({
                key: hiddenDataKey,
                label: (POSTAL && POSTAL.prefix) || CIVIC.prefix,
                hidden: true,
            }, true);
            addresses.push(hiddenAddress);
        }
        const POSTAL_CIVIC_ADDRESS = [
            {
                groupFields: true,
                subGroup: true,
                className: 'row  flex-wrap-reverse',
                fieldGroup: addresses,
            },
        ];
        return this.configureForm(POSTAL_CIVIC_ADDRESS);
    }
    markFormGroupDirty(formGroup) {
        Object.values(formGroup.controls).forEach((control) => {
            control.markAsDirty();
            if (control.controls) {
                this.markFormGroupDirty(control);
            }
        });
    }
    setAllFieldsRequiredState(fields, state = false, ignoreFields) {
        fields.forEach(field => {
            if (field.expressionProperties) {
                if (ignoreFields && ignoreFields.includes(field.key)) {
                    field.expressionProperties['templateOptions.required'] = String(!state);
                }
                else {
                    field.expressionProperties['templateOptions.required'] = String(state);
                }
            }
            if (field.templateOptions) {
                if (ignoreFields && ignoreFields.includes(field.key)) {
                    field.templateOptions['required'] = !state;
                }
                else {
                    field.templateOptions['required'] = state;
                }
            }
            if (field.fieldGroup) {
                this.setAllFieldsRequiredState(field.fieldGroup, state, ignoreFields);
            }
        });
    }
    getAddressFromConfig(addressConfig, notRequired = false, keyToIdentifyLabel = '', defaultValue = 'CA') {
        const label = addressConfig.label || `${addressConfig.prefix}${addressConfig.key}`;
        const idLabel = (keyToIdentifyLabel && keyToIdentifyLabel.length) ? keyToIdentifyLabel : label; // to differentiate ids within the same component
        let fieldset = {
            groupFields: true,
            key: addressConfig.key,
            label,
            className: `${addressConfig.className ? addressConfig.className : 'col-6'} ${addressConfig.hidden ? 'hidden' : ''}`,
            changeExpr: addressConfig.changeExpr,
            fieldGroup: [
                {
                    type: RAW_FORM_TYPE_CHECKBOX,
                    key: ADDRESS_FIELDS.sameAsOrganizationAddress,
                    hideExpression: addressConfig.sameAsOrganization && addressConfig.sameAsOrganization.hide,
                    changeExpr: addressConfig.sameAsOrganization && addressConfig.sameAsOrganization.changeExpr,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.sameAsOrganizationAddress',
                    disabled: false,
                    required: false,
                    className: 'col-12',
                    id: `${idLabel}sameAsOrganizationAddress`,
                },
                {
                    type: RAW_FORM_TYPE_TEMPLATE,
                    label: '&nbsp;',
                    className: 'col-12 spacer',
                    hideExpression: addressConfig.sameAsOrganization ? !addressConfig.sameAsOrganization.spacer : true,
                },
                {
                    type: RAW_FORM_TYPE_CHECKBOX,
                    key: addressConfig.sameAsPostalAddressKey || ADDRESS_FIELDS.sameAsPostalAddress,
                    hideExpression: addressConfig.sameAsPostal && addressConfig.sameAsPostal.hide,
                    changeExpr: addressConfig.sameAsPostal && addressConfig.sameAsPostal.changeExpr,
                    label: addressConfig.sameAsPostalAddressLabel || 'REGISTRATION_PAGE.cfsRegistrationForm.sameAsPostalAddress',
                    disabledExpression: addressConfig.disabled || addressConfig.sameAsPostalAddressDisabled || false,
                    required: false,
                    className: 'col-12',
                    id: `${idLabel}sameAsPostalAddress`,
                },
                {
                    type: RAW_FORM_TYPE_DICT_SELECT,
                    key: ADDRESS_FIELDS.country,
                    source: addressConfig.countrySrc || SOURCE_DATA_TYPE.COUNTRY,
                    dataType: addressConfig.countryDataType,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                    disabledExpression: addressConfig.disabled === false || addressConfig.disableCountryField || false,
                    required: !notRequired && addressConfig.optional !== true,
                    defaultValue: defaultValue,
                    sort: false,
                    translatePrefix: 'COMMON.countryList',
                    id: 'country',
                    onChange: (field) => {
                        if (field.event && !field.event.initial) {
                            field.form && field.form.get('postalCode').reset();
                        }
                    }
                },
                {
                    type: RAW_FORM_TYPE_DUMMY,
                },
                {
                    type: RAW_FORM_TYPE_INPUT,
                    key: ADDRESS_FIELDS.streetNumber,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                    required: !notRequired && addressConfig.optional !== true,
                    disabledExpression: addressConfig.disabled || false,
                    id: `${idLabel}streetNumber`,
                },
                {
                    type: RAW_FORM_TYPE_INPUT,
                    key: ADDRESS_FIELDS.streetName,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                    disabledExpression: addressConfig.disabled || false,
                    required: !notRequired && addressConfig.optional !== true,
                    id: `${idLabel}streetName`,
                },
                {
                    type: RAW_FORM_TYPE_INPUT,
                    key: ADDRESS_FIELDS.city,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                    disabledExpression: addressConfig.disabled || false,
                    required: !notRequired && addressConfig.optional !== true,
                    id: `${idLabel}city`,
                },
                {
                    type: RAW_FORM_TYPE_DICT_SELECT,
                    key: ADDRESS_FIELDS.province,
                    source: SOURCE_DATA_TYPE.ALL_PROVINCE,
                    translatePrefix: 'COMMON.jurisdictionsList',
                    label: addressConfig.provinceStateLabel ? addressConfig.provinceStateLabel : 'REGISTRATION_PAGE.cfsRegistrationForm.provState',
                    disabledExpression: addressConfig.disabled || false,
                    filter: { field: 'country', prop: 'code' },
                    skipItems: addressConfig.disableCountryField && ['OC'],
                    requiredExpression: !notRequired && addressConfig.optional !== true && 'model && [\'CA\', \'US\'].includes(model.country)',
                    id: `${idLabel}province`,
                    freeTextExpr: addressConfig.provinceAsFreeTextExpr,
                    dataProvided: !!addressConfig.privinceData,
                    items: addressConfig.privinceData,
                },
                {
                    type: RAW_FORM_TYPE_INPUT,
                    key: ADDRESS_FIELDS.poBox,
                    hideExpression: addressConfig.hidePoBox,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                    disabledExpression: addressConfig.disabled || false,
                    required: false,
                    className: 'col-6',
                    id: `${idLabel}poBox`,
                },
                {
                    type: RAW_FORM_TYPE_INPUT,
                    key: ADDRESS_FIELDS.postalCode,
                    label: addressConfig.postalCodeLabel ? addressConfig.postalCodeLabel : 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                    disabledExpression: addressConfig.disabled || false,
                    requiredExpression: !notRequired && addressConfig.optional !== true && 'model && [\'CA\', \'US\'].includes(model.country)',
                    id: `${idLabel}postalCode`,
                },
            ],
        };
        if (addressConfig.tooltip)
            [
                fieldset = Object.assign({}, fieldset, { icon: {
                        text: CARApplyPrefix(addressConfig.tooltip, CAR_STEP_PREFIX)
                    } })
            ];
        if (!addressConfig.hideLatLong) {
            fieldset.fieldGroup.push({
                type: RAW_FORM_TYPE_INPUT,
                key: ADDRESS_FIELDS.latitude,
                hideExpression: addressConfig.hideLatLong,
                label: 'REGISTRATION_PAGE.nfmpAccountForm.latitude',
                disabledExpression: addressConfig.disabled || false,
                required: !!addressConfig.requiredLatLong,
                validators: ['number', 'lonlatDecimal'],
                max: 90,
                min: -90,
                modelOptions: {
                    updateOn: 'blur',
                },
                id: `${idLabel}latitude`,
            }, {
                type: RAW_FORM_TYPE_INPUT,
                key: ADDRESS_FIELDS.longitude,
                label: 'REGISTRATION_PAGE.nfmpAccountForm.longitude',
                hideExpression: addressConfig.hideLatLong,
                disabledExpression: addressConfig.disabled || false,
                required: !!addressConfig.requiredLatLong,
                validators: ['number', 'lonlatDecimal'],
                max: 180,
                min: -180,
                modelOptions: {
                    updateOn: 'blur',
                },
                id: `${idLabel}longitude`,
            });
            if (addressConfig.tooltip) {
                fieldset.fieldGroup.forEach(field => {
                    if (field.key === ADDRESS_FIELDS.latitude || field.key === ADDRESS_FIELDS.longitude) {
                        field.icon = {
                            text: CARApplyPrefix(addressConfig.tooltip, CAR_STEP_PREFIX),
                        };
                    }
                });
            }
        }
        return fieldset;
    }
    firstUpper(value) {
        const lowercase = value.toLowerCase();
        return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
    }
    configureFieldArray(field) {
        return {
            key: field.key,
            type: 'form-array',
            templateOptions: {
                addText: field.addText,
                removeText: field.removeText,
                formRowClass: field.formRowClass,
                buttonClass: field.buttonClass,
                removeButtons: field.removeButtons,
                disabled: field.disabled,
            },
            fieldArray: {
                fieldGroupClassName: 'row',
                fieldGroup: this.createFieldGroup(field),
            },
        };
    }
    markFormAsDirty(form) {
        Object.values(form.controls).forEach((control) => {
            control.markAsDirty();
            if (control.controls) {
                this.markFormAsDirty(control);
            }
        });
    }
    trimAllPrimitiveFormValues(form) {
        Object.values(form.controls).forEach((control) => {
            if (control.value && (typeof control.value === 'string' || typeof control.value === 'number')) {
                control.setValue(control.value.toString().trim(), { onlySelf: true, emitEvent: false });
            }
            if (control.controls) {
                this.trimAllPrimitiveFormValues(control);
            }
        });
    }
    createInputSwitchField(field) {
        return {
            type: 'input-switch',
            key: field.key,
            className: field.className,
            templateOptions: {
                translate: true,
                label: field.label,
            },
        };
    }
    fileUploadEvent(ev, field) {
        const file = ev.target.files[0];
        this.fileUpload.emit({ file, field });
    }
    inputChange(ev, field) {
        this.inputCallback.next(field);
    }
    // Job Title optional when Account Type is Individual on Offset Form
    offsetFormJobTitleRequiredExpression(form) {
        OFFSET_REGISTRATION_FORM[2].fieldGroup[1]['requiredExpression'] = () => this._isAccountSubTypeOrganization(form);
        return OFFSET_REGISTRATION_FORM;
    }
    // Job Title optional when Account Type is Individual on Contact Person group
    contactPersonJobTitleRequiredExpression(form) {
        CONTACT_PERSON.fieldGroup[1]['requiredExpression'] = () => this._isAccountSubTypeOrganization(form);
        return CONTACT_PERSON;
    }
    _isAccountSubTypeOrganization(form) {
        const control = form.get('accountSubType');
        return control && control.value.id === 11;
    }
    checkPermission(type, field) {
        if (field.permissions && field.permissions[type]) {
            return this.storeSvc.user.hasOneOfThePermission(field.permissions[type]);
        }
        else {
            return true;
        }
    }
    addPrincipalContactPerson(fields) {
        if (this.storeSvc.user.isOffset()) {
            const contactPersonForm = this.configureForm([
                Object.assign({}, CONTACT_PERSON, { label: 'REGISTRATION_PAGE.offsetRegistrationForm.principalContactPersonProfile' }),
            ])[0];
            fields.push(contactPersonForm);
            const contactPersonAddress = this.configurFormPostWithCivicAddress(Object.assign({}, CONTACT_PERSON_PHYSICAL_ADDRESS, { sameAsPostal: {
                    hide: false,
                    changeExpr: `this.disableForm($event, field); this.cloneFormGroup($event, field, 'masterAccount.address', 'contactPerson.postalAddress');`,
                } }), CONTACT_PERSON_MAILING_ADDRESS)[0];
            contactPersonAddress.wrappers = ['app-formly-fieldset'];
            contactPersonAddress.templateOptions = {
                label: this.translateService.instant('REGISTRATION_PAGE.offsetRegistrationForm.principalContactPersonAddress'),
            };
            fields.push(contactPersonAddress);
        }
        return fields;
    }
    getCMAccountRegistrationForm(accType, isSubAccount = false) {
        const cmRegForm = [];
        let offsetRegForm = cloneDeep(OFFSET_REGISTRATION_FORM);
        let APRaddress;
        if (accType === OMP_ACCOUNT_TYPE.PUBLIC_GOVERNMENT) {
            offsetRegForm.splice(0, 1, CM_PB_GOV_PROFILE);
            offsetRegForm[1]['label'] = 'REGISTRATION_PAGE.offsetRegistrationForm.publicGovernmentAddress';
        }
        else {
            offsetRegForm.splice(0, 1, ...CM_ORG_PROFILE);
        }
        offsetRegForm[1].fieldGroup[0]['disabled'] = false;
        offsetRegForm[2].fieldGroup[0]['disabled'] = false;
        if (isSubAccount) {
            // Don't need the Master account data in this case as we already have it
            offsetRegForm = [offsetRegForm[0]];
        }
        else {
            APRaddress = this.configurFormPostWithCivicAddress(PHYSICAL_ADDRESS_FORM_SECTION, MAILING_ADDRESS_FORM_SECTION)[0];
            APRaddress.wrappers = ['app-formly-fieldset'];
            APRaddress.templateOptions = { label: 'REGISTRATION_PAGE.offsetRegistrationForm.APRAddress' };
        }
        const contactPersonFields = cloneDeep(CONTACT_PERSON);
        const contactPerson = this.configureForm([
            contactPersonFields
        ])[0];
        const contactPersonAddress = this.configurFormPostWithCivicAddress(CONTACT_PERSON_PHYSICAL_ADDRESS, CONTACT_PERSON_MAILING_ADDRESS)[0];
        contactPersonAddress.wrappers = ['app-formly-fieldset'];
        contactPersonAddress.templateOptions = {
            label: 'REGISTRATION_PAGE.offsetRegistrationForm.contactPersonAddress',
        };
        cmRegForm.push(...this.configureForm(offsetRegForm));
        if (!isSubAccount)
            cmRegForm.push(APRaddress);
        cmRegForm.push(contactPerson);
        cmRegForm.push(contactPersonAddress);
        return cmRegForm;
    }
    enableFieldByPermission(form, field, permissions) {
        if (this.storeSvc.user.hasOneOfThePermission(permissions)) {
            form.get(field).enable();
        }
    }
    addPostalAddressValidator(form, controlNamePrefix, required = true, postalCode = 'postalCode', country = 'country') {
        if (required) {
            form.get(controlNamePrefix + postalCode).setValidators([Validators.required, postalCodeValidator]);
        }
        else {
            form.get(controlNamePrefix + postalCode).setValidators([postalCodeValidator]);
        }
        form.get(controlNamePrefix + postalCode).updateValueAndValidity();
        form.get(controlNamePrefix + country).valueChanges.subscribe(() => {
            form.get(controlNamePrefix + postalCode)
                .updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });
    }
    enableLatLongWithDisabledForm($event, field) {
        this.disableForm($event, field, null, null);
        const latitude = field.form.get('latitude');
        const longitude = field.form.get('longitude');
        if (latitude && longitude) {
            latitude.enable();
            longitude.enable();
        }
    }
    createtextOnlyField(field) {
        return field;
    }
}
FormFactoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormFactoryService_Factory() { return new FormFactoryService(i0.ɵɵinject(i1.LookupService), i0.ɵɵinject(i2.SubmissionManagementService), i0.ɵɵinject(i3.AgreementCreditsService), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.StoreService), i0.ɵɵinject(i6.AgreementToTransferCreditService)); }, token: FormFactoryService, providedIn: "root" });
